import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
declare var $: any;

// var $: any;
@Injectable({
  providedIn: 'root'
})

export class SharedServiceService {

  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  constructor(public http: HttpClient, private router: Router) { }

  login(request) {
    return this.http.post(environment.apiUrl + "oms_api/v1/login", request);
  }
  

  generateRefreshToken() {
    let requestBody = {
      refresh: this.getRefreshToken()
    }
    return this.http.post(environment.apiUrl + "oms_api/v1/vida-o-token/refresh/", requestBody).pipe(
      catchError(errorData => {
        if (errorData.status === 401) {
          this.saveTokens({ access: null, refresh: null });
          localStorage.removeItem("refreshtoken");
          this.logoutThis();
          localStorage.setItem("sessionExpired", "yes");
          setInterval(() => {
            localStorage.removeItem("sessionExpired");
          }, 5000);
          return Observable.throw(errorData);
        }
        if (errorData.status === 500) {
          console.log("giving 500 error");
          $.notify({
            icon: "add_alert",
            message: "Please try after some time"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      })
    );
  }
  getToken() {
    return localStorage.getItem("token") || '';
  }
  getRefreshToken() {
    return localStorage.getItem("refreshtoken") || '';
  }
  saveTokens(tokendata: any) {
    localStorage.setItem("token", tokendata.access);
    localStorage.setItem("refreshtoken", tokendata.refresh);
  }

  logoutThis() {
    localStorage.clear();
    this.router.navigate(['/']);
    

  }
  logoutThisNow() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
  getAllStores(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores" + "?limit=" + data.limit + "&offset=" + data.offset);
  }



  getAllCatalog(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/getallproducts" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getInventoryDetails(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/getinventries" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getAllStoresWD() {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores");
  }

  getSelectedStoreInfo(storeID) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/" + storeID);
  }

  createNewStoreAPI(Request) {

    return this.http.post(environment.apiUrl + "oms_api/v1/stores", Request);
  }

  updateSelecteStore(res, body) {

    return this.http.put(environment.apiUrl + "oms_api/v1/stores/" + res, body);
  }

  updateTheAddressOfSelectedStore(res, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/addresses/" + res, body);
  }
  deleteTheSelectedStore(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/stores/" + id);
  }

  getListOfOrders() {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders");
  }

  getOrdersByID(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders" + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed);
  }



  getOrdersByStrideRite(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name);
  }
  getOrdersByAquatalia(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name);
  }
  getOrdersByAndreAssous(data) {
   
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name);
  }

  getOrdersByJambu(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name);
  }
  getOrdersByWallmart(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name);
  }
  getOrdersByEbay(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/ordersbychannel/" + data.user_id + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed + "&channel_name=" + data.channel_name)
  }

  getOrdersByCancel(data, reqBody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=false&is_refund=false&issue_refund=false&is_hold=false",reqBody);
  }
  getOrdersByRefundAndReplacement(data, reqBody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=true&is_refund=false&issue_refund=false&is_hold=false", reqBody);
  }

  getOrdersByReturnandrefund(data, reqBody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=false&is_refund=true&issue_refund=false&is_hold=false", reqBody);
  }



  getOrdersByReturnOrder(data, reqBody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=false&is_refund=false&issue_refund=false&is_hold=false", reqBody);
  }

  getOrdersGroupByChannelID(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders" + "?groupbychannel=" + data.groupbychannel);
  }

  getPaymentFailedOrdersByID(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/transactions/failed" + "?limit=" + data.limit + "&offset=" + data.offset + "&groupbychannel=" + data.groupbychannel);
  }

  getWMSOrdersByID(data) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders/wms" + "?limit=" + data.limit + "&offset=" + data.offset + "&groupbychannel=" + data.groupbychannel);
  }
  getReturnOrdersByID(data) {
   
    return this.http.get(environment.apiUrl + "oms_api/v1/getreturns?limit=" + data.limit + "&offset=" + data.offset + "&groupbychannel=" + data.groupbychannel);
  }


  getAllReturnOrders(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/getreturns?limit=" + data.limit + "&offset=" + data.offset);
  }

  getAllPaymentFailedOrders(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/transactions/failed" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getOrdersForCRM(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/crm_orders", body);
  }

  getAselectedOrderDetails(orderID) {
    console.log(orderID);
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/" + orderID);
    
  }
  
  getAselectedOrderDetailsPf(payment_id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/transactions/failed/" + payment_id);
  }

  getAllOrderStatus() {
    return this.http.get(environment.apiUrl + "oms_api/v1/statuses");
  }
  getAllReturnOrderStatus() {
    return this.http.get(environment.apiUrl + "oms_api/v1/returnstatuses");
  }

  getRefundReasons() {
    return this.http.get(environment.apiUrl + "oms_api/v1/reasons");
  }
  updateTheOrderStatus(id, status_id) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/" + id, status_id);
  }

  getAllCustomerInfo(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/customers" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getSpesificCustomerInfo(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/customers/" + id);
  }
  sodexoRefund(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/sodexo/refund", body);
  }

  getAllCartInfo(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/user_carts" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getSelectedCartInfo(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/carts/" + id);
  }

  createSlotsForStore(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/slots", body);
  }

  getSelectedStoreSlots(res) {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/" + res + "/slots");
  }

  createFrequency(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/frequency", body);
  }

  getAllFrequency() {
    return this.http.get(environment.apiUrl + "oms_api/v1/frequency");
  }

  selectedFrequencyDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/frequency/" + id);
  }

  updateTheFrequency(id, body) {

    return this.http.put(environment.apiUrl + "oms_api/v1/frequency/" + id, body);
  }
  deleteTheselectedFrequency(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/frequency/" + id);
  }

  createDeliveryOptions(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_option", body);
  }

  getAllDeliveryOptions() {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_option");
  }

  selectedDeliveryOptionDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_option/" + id);
  }

  updateTheDeliveryOptions(id, body) {

    return this.http.put(environment.apiUrl + "oms_api/v1/delivery_option/" + id, body);
  }

  createNewSubscription_typeAPI(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_option", body);
  }

  getAllRoles() {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles");
  }

  adminCreateNewUser(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/oms_users", body);
  }

  

  getALLuserInformation(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/oms_users" + "?limit=" + data.limit + "&offset=" + data.offset);
  }
  getSlectedUserDetails(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/oms_users/" + id);
  }

  updateTheStoreSlots(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/slots", body);
  }

  updateTheSelectedUser(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/oms_users/" + id, body);
  }

  deleteTheSelectedUser(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/oms_users/" + id);
  }

  adminCreateNewRole(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/roles", body);
  }
  getListOfRoles(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  deleteTheSelectedRole(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/roles/" + id);
  }
  getTheSelectedRoleInformation(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + id);
  }

  updatetheSelectedRole(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/roles/" + id, body);
  }

  deleteTheselectedDeliveryOption(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/delivery_option/" + id);
  }

  getAllSubscriptionDetails() {
    return this.http.get(environment.apiUrl + "oms_api/v1/subscriptions");
  }

  deleteTheselectedSubscriptionSerivece(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/subscriptions/" + id);
  }

  createNewSubscription_service(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/subscriptions", body);
  }

  getSelectedSubscriptionAPI(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/subscriptions/" + id);
  }

  updateTheselectedSubscription(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/subscriptions/" + id, body);
  }

  getAllRatingsAndReviews(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/rating_and_reviews" + "?limit=" + data.limit + "&offset=" + data.offset);
  }

  getAllScreensAPI() {
    return this.http.get(environment.apiUrl + "oms_api/v1/screens");
  }

  createNewPermission(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/permissions", body);
  }

  selectedUserPermissions(role_id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + role_id + "/permissions");
  }
  updateTheSelectedUserPerMissions(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/permissions", body);
  }

  downloadCustomeorders(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/filter", body);
  }

  downloadCustomCustomers(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/customers/filter", body);
  }
  downloadCustomeStores(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/stores/filter", body);
  }
  downloadCustomeCart(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/user_carts/filter", body);
  }
  downloadCustomeRatingAndReview(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/rating_and_reviews/filter", body);
  }
  downloadCustomeOMSroles(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/roles/filter", body);
  }
  downloadCustomOMSUsers(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/oms_users/filter", body);
  }

  searchAPI(body) {
    if (body.payment_status) {
      return this.http.post(environment.apiUrl + "oms_api/v1/search" + "?limit=" + body.limit + "&offset=" + body.offset + "&is_payment_failed=" + body.payment_status, body.reqBody);
    } else {
      return this.http.post(environment.apiUrl + "oms_api/v1/search" + "?limit=" + body.limit + "&offset=" + body.offset, body.reqBody);
    }
  }
  searcAPIBYUPC(body) {
   

    return this.http.post(environment.apiUrl + "oms_api/v1/search_inventry" + "?limit=" + body.limit + "&offset=" + body.offset, body.reqBody);
    
  }
  searchgiftcard(body) {
    
    return this.http.post(environment.apiUrl + "oms_api/v1/searchgiftcard" + "?limit=" + body.limit + "&offset=" + body.offset, body.reqBody);
    
  }

  CatalogueSearchAPI(body) {
   

    return this.http.post(environment.apiUrl + "oms_api/v1/searchshopifyproducts" + "?limit=" + body.limit + "&offset=" + body.offset, body.reqBody);
    
  }

  walte(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/wallets/transactions", body);
  }

  dashboardAPI(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/dashboard", body);
  }
  getDashboardAPI() {
    return this.http.get(environment.apiUrl + "oms_api/v1/dashboard");
  }
  createDeliveryCharges(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/delivery_charges", body);
  }
  getDeliveryCharges(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/delivery_charges?store_code=" + id);
  }

  getMinimumOrderValues(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/min_orders_value?store_code=" + id);
  }

  getPackagingValue(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types?store_code=" + id);
  }


  deleteTheDeliveryCharge(Id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/delivery_charges/" + Id);
  }
  async updateBulk(body) {
    return await this.http.put(environment.apiUrl + "oms_api/v1/orders/update", body).toPromise();
  }
  financeReportDownload(body) {
    
    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, { responseType: "blob" });
  }
  transactionReportDownload(body) {
    
    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, { responseType: "blob" });

  }
  apeasmentReportDownload(body) {
    

    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, { responseType: "blob" });

  }
  walletReportDownload(body) {
    
    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, { responseType: "blob" });

  }
  downloadNewReports(body) {
   
    return this.http.post(environment.apiUrl + "oms_api/v1/reports", body, { responseType: "blob" });

  }

  GetCustomerDetails(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/getcustomer", body);
  }

  productSearch(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/search", body);
  }




  addNewAddress(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/addresses", body);
  }

  deleteAddress(id) {


    return this.http.delete(environment.apiUrl + "oms_api/v1/addresses/" + id);
  }

  getCustomerAddress() {


    return this.http.get(environment.apiUrl + "oms_api/v1/addresses");
  }
  updateCustAddress(id, body) {


    return this.http.put(environment.apiUrl + "oms_api/v1/addresses/" + id, body);
  }

  addtoCart(body) {


    return this.http.put(environment.apiUrl + "oms_api/v1/carts", body);
  }

  getCartDetails() {


    return this.http.get(environment.apiUrl + "oms_api/v1/carts");
  }

  deleteCartProduct(data) {

    return this.http.delete(environment.apiUrl + "oms_api/v1/carts/items/" + data);
  }

  deleteCart() {

    return this.http.delete(environment.apiUrl + "oms_api/v1/carts/items");

  }

  getPackagingTypes(query) {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types", { params: query });
  }
  updatePrice(data) {
    return this.http.put(environment.apiUrl + "oms_api/v1/carts/prices", data);
  }

  updatePackagingType(data) {

    return this.http.put<any>(environment.apiUrl + "oms_api/v1/carts/packaging_types", data);
  }


  createminOrderVal(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/min_orders_value", body);
  }

  getMinimumOrderValueInfo() {

    return this.http.get(environment.apiUrl + "oms_api/v1/min_orders_value");

  }
  updateMiniOrderval(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/min_orders_value", body);
  }
  createPackagingType(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/packaging_types", body);
  }

  getPackagingType() {
    return this.http.get(environment.apiUrl + "oms_api/v1/packaging_types");
  }

  editPackagingType(id) {
    return this.http.put(environment.apiUrl + "oms_api/v1/packaging_types", id);
  }

  deletePackagingType(id) {

    return this.http.delete(environment.apiUrl + "oms_api/v1/packaging_types/" + id);
  }

  placeAnOrder(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/payments", body);
  }

  getPaymentStatus(data) {
    return this.http.get(environment.apiUrl + 'oms_api/v1/payments/' + data);
  }

  userCreationGetOTP(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/getotp", body);
  }
  userCreationVerifyOTP(body) {
    return this.http.post(environment.magentoAPIURL + "rest/V1/customapi/registration", body);
  }

  removeItems_from_order(orderID, itemID, msg) {
    
    var form_data = new FormData();
    for (var key in msg) {
      form_data.append(key, msg[key]);
    }

    return this.http.post(environment.apiUrl + "oms_api/v1/orders/" + orderID + "/items/remove/" + itemID, form_data);
  }

  updateTheOrderByOMS(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/" + id + "/items", body);
  }

  OmsServiceblityCheck(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/serviceability", body);
  }

  public getSlots() {
   
    return this.http.get<any>(environment.apiUrl + 'oms_api/v1/stores/' + localStorage.getItem('plant_code') + '/tms_slots');
  }

  updaeTimeSlot(requestBody, id) {
    return this.http.put(environment.apiUrl + `oms_api/v1/orders/${id}/edit_slot`, requestBody);
  }

  getAllCoupons(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/getgc?limit=" + data.limit + "&offset=" + data.offset);
  }

  getCouponByID(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/coupons/" + id);
  }
  createNewCoupan(body) {
    
    return this.http.post(environment.apiUrl + "oms_api/v1/creategc", body);
  }

  updateTheCoupons(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/coupons/" + id, body);
  }

  deleteTheSelectedCoupon(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/coupons/" + id);
  }

  moveToSuccess(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/payment_failed/update", body);
  }

  getInvoiceData(ID) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/" + ID + "/bill_printing");
  }

  // Configuration Contacts

  getContactDetails() {
    return this.http.get(environment.apiUrl + "oms_api/v1/contact_details")
  }
  updateContactDetails(reqBody) {
    return this.http.put(environment.apiUrl + "oms_api/v1/contact_details", reqBody)

  }

  // store-wise PaymentMethods

  async getAllStoresIds() {
    return this.http.get(environment.apiUrl + "oms_api/v1/stores/store_code");
  }
  getPaymentStoreWise(storeCode) {
    return this.http.get(environment.apiUrl + "oms_api/v1/payment_methods?store_code=" + storeCode);
  }
  updateStorepaymentMethods(reqBody) {
    return this.http.put(environment.apiUrl + "oms_api/v1/payment_methods/update", reqBody);
  }

  //Get WMS Order lists 
  getAllWmsOrders(data) {
    return this.http.get(environment.apiUrl + "oms_api/v1/roles/" + data.user_id + "/orders/wms" + "?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=" + data.is_payment_failed);
  }

  downloadCustomeWmsorders(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/orders/wms_sales_order", body);
  }

  productRemovel(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/remove_bulk_products", body);
  }

  walletRevasal(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/wallets/refund", body);
  }

  getAllAddress() {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders");
  }


  getAdressById(id) {
    return this.http.get(environment.apiUrl + `oms_api/v1/orders/${id}/order_addresses`);
  }
  updateAddress(requestBody, id) {
    return this.http.put(environment.apiUrl + `oms_api/v1/orders/${id}/order_addresses`, requestBody);
  }
  bulkProdukPrint(requestBody) {
    return this.http.post(environment.apiUrl + 'oms_api/v1/bulk_pdf', requestBody, { responseType: 'text' });
  }
  getNotifications() {
    return this.http.get(environment.apiUrl + 'oms_api/v1/notifications')
  }
  getNotificationsID(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/notifications/" + id);
  }
  deleteNotifications(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/notifications/" + id);
  }
  updateNotifications(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/notifications/" + id, body);
  }
  postNotifications(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/notifications", body);
  }
  getSmsTemplate() {
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms_template')
  }
  getSmsTemplateID(id) {
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms_template/' + id)
  }
  updateSmsTemplate(id, body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/sms_template/" + id, body);
  }
  getSmsTriggerOn() {
    return this.http.get(environment.apiUrl + 'oms_api/v1/get_sms_email_references')
  }
  deleteSmsTemplate(id) {
    return this.http.delete(environment.apiUrl + "oms_api/v1/sms_template/" + id);
  }
  createSmsTemplate(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/sms_template", body);
  }
  getRatedAndReviewID(id) {
    return this.http.get(environment.apiUrl + `oms_api/v1/orders/${id}/rating_and_reviews`);
  }
  
  getSmsVariable() {
    return this.http.get(environment.apiUrl + 'oms_api/v1/sms/input_variables')
  }
  
  buzzer(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/buzzer_status", body);
  }
  
  getbuzzer() {
    return this.http.get(environment.apiUrl + "oms_api/v1/all_buzzer_status");
  }

  

  getInventory() {
    return this.http.get(environment.apiUrl + "oms_api/v1/get_product_inventory");
  }

  upDateTheSelectedInventory(body) {
    return this.http.put(environment.apiUrl + "oms_api/v1/update_product_inventory", body);
  }


  updateOrderNotes(orderid, body) {
    
    return this.http.put(environment.apiUrl + "oms_api/v1/orders/" + orderid, body);
  }



  notesCreated(orderid, body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/notes/" + orderid + "/user_notes", body);
  }

  notesHistory(id) {
    
    return this.http.get(environment.apiUrl + "oms_api/v1/notes/" + id + "/user_notes");
  }
  auditLogsHistory(id) {
    return this.http.get(environment.apiUrl + "oms_api/v1/notes/" + id + "/system_audits");
  }

  cancelOrderFun(orderno, email, channel) {

    return this.http.post(environment.apiUrl + "oms_api/v1/shopify/cancel/" + encodeURIComponent(orderno) + "?user_email=" + email + "&channel_name=" + channel, {});

  }
  refundCancelFun(data) {

    return this.http.post(environment.apiUrl + "oms_api/v1/refund/cancelled?channel_name=" + data.channel_name, {});
  }
  walmartcancelrefundFun() {
    return this.http.post(environment.apiUrl + "oms_api/v1/walmart/refunds", {});
  }
  returnRefundFun(data) {

    return this.http.post(environment.apiUrl + "oms_api/v1/refund/return_refund?channel_name=" + data.channel_name, {});
  }
  walmartReturnAndRefund() {
    return this.http.post(environment.apiUrl + "oms_api/v1/walmart/returnrefund", {});
  }

  initiateOrderReplacement(orderid) {
    return this.http.get(environment.apiUrl + "oms_api/v1/orders/" + orderid)
  }
  fedexshippinglabelApi(body) {
    return this.http.post(environment.apiUrl + "oms_api/v1/fedexreturn/requests", body)
  }
  
  searchorderbyID(requestbody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/searchitems", requestbody)
  }


  
  searchcheckorderstatus(requestbody) {
    return this.http.get(environment.apiUrl + "oms_api/v1/oez?order_no=" + requestbody.order_no + "&email=" + requestbody.email + "&permission=" + requestbody.permission + "&user_email=" + requestbody.user_email)
    
  }


  getallreason() {
    return this.http.get(environment.apiUrl + "oms_api/v1/get_returnreasons")
  }

  orederReplacement(request) {
    return this.http.post(environment.apiUrl + "oms_api/v1/create/replace", request);
  }

  ordershipmentoptions() {
    return this.http.get(environment.apiUrl + "oms_api/v1/getshipmentoptions")
  }

  gethipmentservicetypes(reqbody) {

    return this.http.get(environment.apiUrl + "oms_api/v1/getshipmentservicetypes?shipping_option=" + reqbody.shipping_option)
  }

  initiatereturnorders(requestBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/return/requests?limit=10&offset=0", requestBody);
  }
  appeasementrefund(requestBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/appeasement/refund", requestBody);
  }
  newappeasementreason(orderno, reqstbdy) {
    return this.http.post(environment.apiUrl + "oms_api/v1/refund_calculate/" + orderno, reqstbdy);
  }
  pushingOrders(dateAndTimerequestBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/m3/reexport_orders", dateAndTimerequestBody);
  }
  inventoryNewReports() {
    return this.http.get(environment.apiUrl + "oms_api/v1/download_allinventories", { responseType: "blob" })
  }
  catalogNewReports() {
    return this.http.get(environment.apiUrl + "oms_api/v1/download_catalogue", { responseType: "blob" })
  }


  giftcardremainingvalues(orderid) {
    return this.http.get(environment.apiUrl + "oms_api/v1/getremvalue/" + orderid);
  }

  saveTheEditedAddressInOrderViewPageAPI(requestBody) {
    return this.http.put(environment.apiUrl + "oms_api/v1/updatesoa", requestBody);
  }

  saveTheEditedAddressInReturnsPageAPI(requestBody) {
    return this.http.put(environment.apiUrl + "oms_api/v1/update_address", requestBody);
  }

  getHoldOrders(data, reqBody) {

    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=false&is_refund=false&issue_refund=false&is_hold=true", reqBody);
  }

  saveeditedUpcforlineitem(reqbody) {
   
    return this.http.post(environment.apiUrl + "oms_api/v1/update_upc_for_holdorders", reqbody)
  }

  cancelliteminorder(reqbody) {
    return this.http.post(environment.apiUrl +"oms_api/v1/cancel_item_without_upc", reqbody )
  }

  holdOrderFun(reqBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/ccd_hold_activate", reqBody)
  }
  issuerefundorder(reqBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/issue_refund", reqBody)
  }

  getOrdersBydelayedreturns(data, reqBody) {

    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=false&is_refund=false&issue_refund=true&is_hold=false", reqBody);
  }

  createFinalTranscationSubmit(requestBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/shopify/app_ref", requestBody);
  }
  Refundcalculateforsubmit(reqbody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/shopify/refundcalculate", reqbody);
  }
  applydiscountForAppeasment(requestBody) {
    return this.http.post(environment.apiUrl + "oms_api/v1/get_appeasement_amount", requestBody);
  }

  getOrdersByReturnwithoutlabel(data, reqBody) {


    return this.http.post(environment.apiUrl + "oms_api/v1/roles/" + data.id + "/orders?limit=" + data.limit + "&offset=" + data.offset + "&is_payment_failed=false&is_replacement=true&is_refund=true&issue_refund=false&is_hold=false", reqBody);
  }

}


