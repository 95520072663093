import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends
  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, "dd/MM/yyyy");
  }
}

@Pipe({
  name: 'customDateAndTime'
})
export class CustomDateAndTimePipe extends
  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, "d MMMM y hh:mm a");
  }
}

@Pipe({
  name: 'auditLogCustomOrderExport'
})
export class AuditLogCustomOrderExport {
  transform(value: any, args?: any): any {
    var data = value.split('-');
    console.log(data);    
    return data[0];
  }
}
@Pipe({
  name: 'auditLogCustomOrderExportInfo'
})
export class AuditLogCustomOrderExportInfo {
  transform(value: any, args?: any): any {
    var data = value.split('-');
    console.log(data);    
    return data[1];
  }
}