import {Directive, Input, HostBinding} from '@angular/core'
@Directive({
  selector: 'img[default]',
  host: {
    '(error)':'updateUrl()',
    '(load)': 'load()',
    '[src]':'src'
   }
})
export class ImgDirective {

  constructor() { }
  @Input() src:string  | undefined;
  @Input() default:string | undefined;
  @HostBinding('class') className: any;

  updateUrl() {
    console.log(this.default);
    this.src = this.default;
  }
  load(){
    this.className = 'image-loaded';
  }
}

