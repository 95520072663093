import {
  Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef,
  HostListener, ElementRef, ChangeDetectionStrategy
} from '@angular/core';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedServiceService } from 'app/_services/shared-service.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-stride-rite-initiate-return',
  templateUrl: './stride-rite-initiate-return.component.html',
  styleUrls: ['./stride-rite-initiate-return.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class StrideRiteInitiateReturnComponent implements OnInit {
  buttonHover = ""
  filterOrders = "";
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForView: any;
  selectedInfoForEdit: any;
  finalData: { order_id: string; order_date: string; delivery_date: string; customer_name: string; payment_status: string; order_type: string; status: string; }[];
  selectedAlldata = [];
  selectedAlldataArray = [];
  allOrdersList: any;
  loader: boolean;
  message_display: boolean = false;
  showThisScreenWithPermissions: any;
  permissionForOnlyThisScreen = [];
  allPermissionForOnlyThisScreen: any;
  statustotalList: any;
  custome_download: FormGroup;
  productBulkRemoval: FormGroup;
  custome_search: FormGroup;
  submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  pageOfItems: any;
  first_id: any;
  last_id: any;
  totalElements: any;
  forFilterUse: any;
  fullfillData: any;
  config: any;
  id: any;
  pager: any = {};
  pagedItems = [];
  finalStatus: any;
  status_with_time: any;
  statusTimeStampArray = [];
  finalArray: any;
  modifiedStatusArray: any;
  statusArray = [];
  updateMesg: any;
  NewStatusArray = [];
  hidethisStatus: boolean;
  NewStatusArray_final = [];
  statusArray_main_one_userMatrix = [];
  currectArray = [];
  statusArray_main_one_userMatrix_include_key = [];
  thisIsMatched: boolean;
  arraychecking = [];
  sendThis_item_s: any;
  sendThis_selected_previous_status: any;
  showThisSelectMSG: boolean;
  showOnlyForCancel: boolean;
  showThisMsgReasonRequired: boolean;
  pageCount = [];
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  onlyForDashboard: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  testingNow: any;
  shareThisCount: any = 20;
  offsetVal: number = 0;
  totalOrders: any;
  public isFliterActive: boolean = false;
  public bulk_update_waiting: boolean = false;
  searchkey: any
  isSubmit: boolean;
  checkNowAll: any;
  walletRevalsal: any;
  public moment: any = moment;
  newUser: any;
  user: string;
  initialLoad: boolean = true
  deliveryDate: any;
  testing: Object;
  delivery = ('#delivery_date'.replace('-', '/'));
  buzzerStatus: any;
  storeCodes: any;
  storedataresponse: any;
  storeCodeDataNew: any;
  allStoresIds: any;
  addingNewiteminOrder: any;
  selectedOMSIDorderID: any;
  selectedOMSIDorderIDfinal: any;
  fromGBCIDInfo: any;
  fromGBCID: any;
  returnOrderIdd: any;
  moveToAllOrdersList: boolean = false;
  orderzipCode: any;
  orderEmail: any;
  totalreasons: any;
  itemsData: any;
  Customerbillingaddresses: any;
  Customershippingaddresses: any;
  returnorderstatus: boolean = false;
  selectedShipperName: any;
  // shipmentservicetypes: any;
  shipmentoptionsData: any;
  shipmentOptionsInfo: any;
  selectedOrderDetails: any;
  selectedShipperServiceData: any;
  shipperServiceData: any;
  shipperServiceInfo: any;
  proceedToInitiatedReturn: boolean;
  returnAndreplacementData: boolean;
  returnAndrefundData: boolean;
  selectedReturenReason: any;
  showThis45DaysError: any;
  showthisitemafterclick: boolean = true;


  constructor(private ref: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder,
    private exportService: ExportService, private service: SharedServiceService, public sharedService: SharedServiceService,
    private detailsData: ExportService, private route: ActivatedRoute) {
    // this.downloadInvoice();
    // this.sortArr("status");
    // this.sortArr1("created_at");
    // this.sortArrOID("order_no");
    // this.sortArrCN("first_name");
    // this.sortArrCN("last_name");
    // this.getAllOrdersList("pageLoad");
    this.fromGBCID = this.route.params.subscribe(params => {
      console.log(params);
      this.fromGBCIDInfo = params;
      if (this.fromGBCIDInfo.order_no) {
        console.log(true);
        this.getOnlyThisOrder("pageLoad", this.fromGBCIDInfo.order_no);
        this.moveToAllOrdersList = true;
      } else {
        this.getAllOrdersList("pageLoad");
        this.route.queryParams.subscribe(queryParams => {
          // do something with the query params
          if (Object.keys(this.route.snapshot.queryParams).length == 0 && !this.initialLoad) {
            this.getAllOrdersList("pageLoad");
          }
        });
        if (Object.keys(this.route.snapshot.queryParams).length != 0 && this.initialLoad) {
          this.initialLoad = false;
          this.filterOrdersByWhenReload(this.route.snapshot.queryParams)
        } else {
          this.initialLoad = false;
          this.getAllOrdersList("pageLoad");
        }

      }
    });


    this.onlyForDashboard = localStorage.getItem('dashboard');
    console.log(this.onlyForDashboard);
    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
    // console.log(this.showThisScreenWithPermissions);

    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }
    // console.log(this.permissionForOnlyThisScreen[0]);
    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];
    console.log(this.allPermissionForOnlyThisScreen);

    this.custome_download = this.formBuilder.group({
      from_date: [''],
      to_date: [''],
      status_id: [0]
    });

    this.productBulkRemoval = this.formBuilder.group({
      skuCode: ['', Validators.required],
      storeSelect: ['default', Validators.required],
      status: ['', Validators.required],
      reason: ['', Validators.required],
      quantity: ['']


    });


    this.custome_search = this.formBuilder.group({
      order_id: [''],
      order_date: [''],
      customer_name: [''],
      payment_type: [''],
      order_status: [''],
      mobile_number: [''],
      delivery_date: [''],

    });
  }



  get a() { return this.custome_download.controls; }
  get b() { return this.custome_search.controls; }

  public selectedStatusInfor(v) {
    // console.log(v);
    // $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#mobile_number').val("");
    $('#delivery_date').val("");


    // $("#order_id_filter").attr("readonly", "true");
    // $("#order_date").attr("readonly", "true");
    // $("#customer_name").attr("readonly", "true");
    // $("#payment_type").attr("readonly", "true");

  }
  public customeExport() {
    this.loader = true;
    this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }
    // console.log(this.custome_download.value);

    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);
    // console.log(this.from_date, this.to_date);
    // console.log(this.to_date - this.from_date);
    if ((this.to_date - this.from_date) >= 0) {
      console.log(this.custome_download.value);
      this.service.downloadCustomeorders(this.custome_download.value).subscribe(res => {
        console.log(res);
        if (res === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.customeData = res;
          // from_date 
          let date = "All order";
          if (this.a.from_date.value && this.a.to_date.value) {
            date = `Order Export - ${moment(this.a.from_date.value).format("DD/MM/YYYY")} -${moment(this.a.to_date.value).format("DD/MM/YYYY")}`;
          }
          this.exportService.exportExcel(this.customeData[0].orderfilter, date);
          this.loader = false;
          $('#from_date').val("");
          $('#to_date').val("");
          $('#selectedStatus').val("");
          this.submitted = false;
          this.custome_download.reset();
        }
      }, err => {
        // console.log(err);
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: err.error.error_desc
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      });
    } else {
      $('#to_date').val("");
      // console.log("to_date");
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }

  }
  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    $('#selectedStatus').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    this.custome_download.reset();
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }
  bulkProduct() {
    $('#sku').val("");
    $('#store_code').val("");
    $('#status').val("");
    $('#reason').val("");
    $('#quantity').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
    // this.custome_download.reset();
  }
  public customeSearch(offset = null, call = null) {
    this.isFliterActive = true;
    if (offset !== null) {
      this.offsetVal = offset
    }

    var request = {
      "reqBody": {
        "screen": "orders",
        "filter_params": [
          {
            "column_name": "order_no",
            "search": $('#order_id_filter').val() || null
          },
          {
            "column_name": "channel_code",
            "search": $('#chanenl_with_filter').val() || null
          },
          {
            "column_name": "email",
            "search": $('#customer_mail_id_filter').val() || null
          },
          {
            "column_name": "name",
            "search": $('#customer_name_id_filter').val() || null
          },
          {
            "column_name": "pincode",
            "search": $('#customer_pincode_filter').val() || null
          }

          // {
          //   "column_name": "email",
          //   "search": $('#customer_mail_id_filter').val() || null
          // },

          // {
          //   "column_name": "order_date",
          //   "search": $('#order_date').val() || null,
          // },
          // {
          //   "column_name": "delivery_date",
          //   "search": $('#delivery_date').val() ? moment(new Date($('#delivery_date').val().toString())).format('DD/MM/YYYY') : null,
          // },
          // {
          //   "column_name": "store_code",
          //   "search": $('#store_code').val() || null
          // },
          // {
          //   "column_name": "order_status",
          //   "search": $('#status_filter').val() || null
          // },
          // {
          //   "column_name": "mobile",
          //   "search": $('#mobile_number').val() || null,
          // }
        ]
      },
      "offset": this.offsetVal,
      "limit": this.shareThisCount
    }
    if ($('#delivery_date').val()) {
      this.deliveryDate = request.reqBody.filter_params[2].column_name
    }
    console.log(this.deliveryDate)
    this.router.navigate(["/initiatereturn"], { queryParams: request.reqBody })
    this.service.searchAPI(request).subscribe(res => {
      this.testing = res;
      if (res === null) {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        this.loader = false;
        this.pagedItems = res[0].orders;
        this.totalOrders = res[0].total_order_count;
        if (call === "firstCall") {
          this.setPage(1);
        }
        // this.loader = false;
        // this.pagedItems = res[0].orders;
        // this.totalOrders = res[0].total_order_count;
        //   this.setPage(1);
        // console.log(this.allOrdersList);
      }
    }, err => {
      // console.log(err);
      this.loader = false;
    });
  }

  // public customeSearch(offset = null, call = null) {
  //   this.isFliterActive = true;
  //   if (offset !== null) {
  //     this.offsetVal = offset
  //   }
  //   if ($('#order_id_filter').val() != "") {
  //     var request = {
  //       "reqBody" : {
  //         "screen": "orders",
  //         "column_name": "order ID",
  //         "search": $('#order_id_filter').val()
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       // console.log(res);
  //       if (res === null) {
  //         this.loader = false;
  //         $('#order_id_filter').val('');
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }
  //         // console.log(this.allOrdersList);
  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });
  //   } else if ($('#order_date').val() != "") {
  //     var request = {
  //       reqBody : {
  //         "screen": "orders",
  //         "column_name": "order date",
  //         "search": $('#order_date').val(),
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       if (res === null) {
  //         this.loader = false;
  //         $('#order_date').val('')
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         // console.log(this.allOrdersList);
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }

  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   }else if ($('#order_date').val() != "") {
  //     var request = {
  //       reqBody : {
  //         "screen": "orders",
  //         "column_name": "order date",
  //         "search": $('#mobile_number').val(),
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       if (res === null) {
  //         this.loader = false;
  //         $('#order_date').val('')
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         // console.log(this.allOrdersList);
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }

  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   } else if ($('#delivary_date').val() != "") {
  //     var request = {
  //       reqBody : {
  //         "screen": "orders",
  //         "column_name": "delivery_date",
  //         "search": $('#delivary_date').val(),
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       if (res === null) {
  //         this.loader = false;
  //         $('#delivary_date').val('')
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         // console.log(this.allOrdersList);
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }

  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   }
  //   // else if ($('#customer_name').val() != "") {
  //   //   var request = {
  //   //     reqBody: {
  //   //     "screen": "orders",
  //   //     "column_name": "customer Name",
  //   //     "search": $('#customer_name').val()
  //   //     },
  //   //     "offset": this.offsetVal,
  //   //     "limit" : this.shareThisCount
  //   //   }
  //   //   this.loader = true;
  //   //   // console.log(request);
  //   //   this.service.searchAPI(request).subscribe(res => {
  //   //     // console.log(res);
  //   //     if (res === null) {
  //   //       this.loader = false;
  //   //       $('#customer_name').val('')
  //   //       $.notify({
  //   //         icon: "add_alert",
  //   //         message: "No Data Found"
  //   //       }, {
  //   //         type: 'info',
  //   //         timer: 1000,
  //   //         placement: {
  //   //           from: 'top',
  //   //           align: 'center'
  //   //         }
  //   //       });
  //   //     } else {
  //   //       this.loader = false;
  //   //       this.pagedItems = res[0].orders;
  //   //       this.totalOrders = res[0].total_order_count;
  //   //       if(call === "firstCall"){
  //   //         this.setPage(1);
  //   //       }
  //   //       // console.log(this.allOrdersList);
  //   //     }
  //   //   }, err => {
  //   //     // console.log(err);
  //   //     this.loader = false;
  //   //   });

  //   // }
  //   //  else if ($('#payment_type').val() != "") {
  //   //   var request = {
  //   //     "reqBody" : {
  //   //       "screen": "orders",
  //   //       "column_name": "payment type",
  //   //       "search": $('#payment_type').val()
  //   //       },
  //   //     "offset": this.offsetVal,
  //   //     "limit" : this.shareThisCount
  //   //   }
  //   //   this.loader = true;
  //   //   // console.log(request);
  //   //   this.service.searchAPI(request).subscribe(res => {
  //   //     // console.log(res);
  //   //     if (res === null) {
  //   //       this.loader = false;
  //   //       $('#payment_type').val('');
  //   //       $.notify({
  //   //         icon: "add_alert",
  //   //         message: "No Data Found"
  //   //       }, {
  //   //         type: 'info',
  //   //         timer: 1000,
  //   //         placement: {
  //   //           from: 'top',
  //   //           align: 'center'
  //   //         }
  //   //       });
  //   //     } else {
  //   //       this.loader = false;
  //   //       this.pagedItems = res[0].orders;
  //   //       this.totalOrders = res[0].total_order_count;
  //   //       if(call === "firstCall"){
  //   //         this.setPage(1);
  //   //       }
  //   //       // console.log(this.allOrdersList);
  //   //     }
  //   //   }, err => {
  //   //     // console.log(err);
  //   //     this.loader = false;
  //   //   });

  //   // }


  //   else if ($('#mobile_number').val() != "") {
  //     var request = {
  //       reqBody : {
  //         "screen": "orders",
  //         "column_name": "mobile",
  //         "search": $('#mobile_number').val(),
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       if (res === null) {
  //         this.loader = false;
  //         $('#mobile_number').val('')
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         // console.log(this.allOrdersList);
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }

  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   } else if ($('#status_filter').val() != "") {
  //     var request = {
  //       "reqBody": {
  //         "screen": "orders",
  //         "column_name": "order status",
  //         "search": $('#status_filter').val()
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       // console.log(res);
  //       if (res === null) {
  //         this.loader = false;
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //           this.setPage(1);
  //         // console.log(this.allOrdersList);
  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   }else if ($('#store_code').val() != "") {
  //     var request = {
  //       "reqBody": {
  //         "screen": "orders",
  //         "column_name": "store_name",
  //         "search": $('#store_code').val()
  //       },
  //       "offset": this.offsetVal,
  //       "limit" : this.shareThisCount
  //     }
  //     this.loader = true;
  //     // console.log(request);
  //     this.router.navigate(["/orders"],{queryParams:request.reqBody})
  //     this.service.searchAPI(request).subscribe(res => {
  //       // console.log(res);
  //       if (res === null) {
  //         this.loader = false;
  //         $('#store_code').val("");
  //         $.notify({
  //           icon: "add_alert",
  //           message: "No Data Found"
  //         }, {
  //           type: 'info',
  //           timer: 1000,
  //           placement: {
  //             from: 'top',
  //             align: 'center'
  //           }
  //         });
  //       } else {
  //         this.loader = false;
  //         this.pagedItems = res[0].orders;
  //         this.totalOrders = res[0].total_order_count;
  //         if(call === "firstCall"){
  //           this.setPage(1);
  //         }
  //         // console.log(this.allOrdersList);
  //       }
  //     }, err => {
  //       // console.log(err);
  //       this.loader = false;
  //     });

  //   } else {

  //   }


  // }
  public reSet() {
    this.deliveryDate = '';
    $('#order_id_filter').val("");

    $('#chanenl_with_filter').val("");
    $('#customer_mail_id_filter').val("");
    $('#customer_name_id_filter').val("");
    $('#order_date').val("");

    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");
    $('#mobile_number').val("");



    // $("#order_id_filter").attr("readonly", "true");
    // $("#order_date").attr("readonly", "true");
    // $("#customer_name").attr("readonly", "true");
    // $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);
    this.offsetVal = 0;
    this.shareThisCount = 20;
    this.isFliterActive = false;
    this.getAllOrdersList("pageLoad");
    // let closeFilter = document.getElementById("collapse2");
    // if(closeFilter.classList.contains('show')){
    //   closeFilter.classList.remove('show');
    // }
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
    // $('.collapsed').trigger('click');
    // $('#heading1').click();
  }
  enableThis1() {
    document.getElementById('order_id_filter').removeAttribute('readonly');
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis2() {
    document.getElementById('order_date').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivary_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis3() {
    document.getElementById('customer_name').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  enableThis4() {
    document.getElementById('payment_type').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    // $('#status_filter').attr('disabled', true);

  }
  // enableThis5() {
  //   // console.log("ghjk");
  //   $('#order_id_filter').val("");
  //   $('#order_date').val("");
  //   $('#customer_name').val("");
  //   $('#payment_type').val("");

  //   $("#order_id_filter").attr("readonly", "true");
  //   $("#order_date").attr("readonly", "true");
  //   $("#customer_name").attr("readonly", "true");
  //   $("#payment_type").attr("readonly", "true");
  // }

  eraiseAll() {
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#store_code').val("");
    $('#delivery_date').val("");

    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }

  // bulkProductRemoval(){
  //   $('#searchInputId').val("");
  //   $('#sku_code').val("");
  //   $('#sote_code').val("");
  //   $('#reason_for_cancle').val("");
  //   $('#status_filter').val("");
  //   let closeDownload = document.getElementById("bulkremoval1");
  //   if(closeDownload.classList.contains('show')){
  //     closeDownload.classList.remove('show');
  //   }
  // }

  all(data) {

    console.log(data);
    this.selectedAlldata = data;
    console.log(this.selectedAlldata);
    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });
      console.log("Checkbox is checked.");
      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {
      console.log("Checkbox is unchecked.");
      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;
      // console.log(this.selectedAlldata);
    }
  }
  generate() {
    console.log(this.ShareThisSelectedList);
    if (this.ShareThisSelectedList > 0) {
      console.log(this.selectedAlldata);
      // var finalData = _.map(this.selectedAlldata, function (item) {
      //   return _.pick(item, "order_no", "created_at", "first_name", "last_name", "payment_method", "status")
      // })
      // finalData = finalData.filter((item) => {
      //   return item != null;
      // });
      // console.log(finalData);  
      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Order No": this.pagedItems[i].order_no,
            "Created At": this.pagedItems[i].created_at,
            "First Name": this.pagedItems[i].first_name,
            "Last Name": this.pagedItems[i].last_name,
            "Payment Method": this.pagedItems[i].payment_method,
            "Status": this.pagedItems[i].status
          }
          finalDataModified.push(use);
        }
      }
      this.exportService.exportExcel(finalDataModified, 'Order Export');
      $('#selectall').prop("checked", false);
      this.getAllOrdersList();
    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  single(event, data) {
    console.log(data);
    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }
    console.log(this.selectedAlldata);

    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);
        // // console.log("Checkbox is checked.");
      } else {
        $("#selectall").prop('checked', false);
        // // console.log("Checkbox is unchecked.");
      }
    });

    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }
    console.log(this.howmanySelectedArray.length);
    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }

  // formatsDateTest: string[] = [
  //   'dd/MM/yyyy hh:mm:ss',
  // ];

  // dateNow: Date = new Date();
  // dateNowISO = this.dateNow.toISOString();
  // dateNowMilliseconds = this.dateNow.getTime();

  //  clk(){
  //     console.log(this.returnOrderIdd)
  //   }
  ngOnInit(): void {
    this.returnreason();
    this.shipmentOptions();

    //  alert( this.walmartTableData())
    // this.service.getbuzzer().subscribe((data)=>{
    //   console.log("buzzer",data)
    //   this.buzzerStatus = data[0].buzzer_status
    //   console.log(this.buzzerStatus)
    // })
    // this.getAllBuzzerStores()
    // setInterval(() => {
    //   this.getAllOrdersList(); 
    // this.getAllBuzzerStores()
    // },30000);

    this.detailsData.viewDetails.subscribe(user => {
      console.log("user", user);
      // this.user = user
    });



    this.getAllStores()

    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 },
      // { "count": 1000 },
    ]

    this.service.getAllOrderStatus().subscribe(res => {
      console.log(res);
      this.statustotalList = res;
      console.log(this.statustotalList.statuses);
      this.finalStatus = this.statustotalList.statuses;

      var data = JSON.parse(localStorage.getItem('screensList'));
      console.log(data);
      console.log(data.user_matrix[0].status);
      this.modifiedStatusArray = data.user_matrix[0].status;
      console.log(this.modifiedStatusArray);
      // var this.currectArray = [];
      for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        if (data.user_matrix[0].status[i].status_name === "placed") {
          var re0 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[0] = re0;
        }
        if (data.user_matrix[0].status[i].status_name === "confirmed") {
          var re1 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[1] = re1;
        }
        if (data.user_matrix[0].status[i].status_name === "pickup_confirmed") {
          var reNew = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[2] = reNew;
        }
        if (data.user_matrix[0].status[i].status_name === "in_process") {
          var re2 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[3] = re2;
        }
        if (data.user_matrix[0].status[i].status_name === "ready_to_dispatch") {
          var re3 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[4] = re3;
        }
        if (data.user_matrix[0].status[i].status_name === "out_for_delivery") {
          var re4 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[5] = re4;
        }
        if (data.user_matrix[0].status[i].status_name === "delivered") {
          var re5 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[6] = re5;
        }
        if (data.user_matrix[0].status[i].status_name === "cancelled") {
          var re6 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }

          this.currectArray[7] = re6;
        }
        // if (data.user_matrix[0].status[i].status_name != "payment_failed") {
        //   var re7 = {
        //     "is_status_read": data.user_matrix[0].status[i].is_status_read,
        //     "is_status_write": data.user_matrix[0].status[i].is_status_write,
        //     "status_id": data.user_matrix[0].status[i].status_id,
        //     "status_name": data.user_matrix[0].status[i].status_name
        //   }

        //   this.currectArray[8] = re7;
        // }
      }
      console.log(this.currectArray);
      // var statusArray = [];
      for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        if (data.user_matrix[0].status[i].is_status_write === true) {
          var status = {
            "status": data.user_matrix[0].status[i].status_name
          }
          this.statusArray_main_one_userMatrix.push(data.user_matrix[0].status[i].status_name);
          this.statusArray_main_one_userMatrix_include_key.push(status);
        }
        // if (data.user_matrix[0].status[i].status_name != "placed") {
        //   var modifiedData = {
        //     "status_name": data.user_matrix[0].status[i].status_name,
        //     "status_id": data.user_matrix[0].status[i].status_id,
        //     "is_status_write": data.user_matrix[0].status[i].is_status_write,
        //     "is_status_read": data.user_matrix[0].status[i].is_status_read
        //   }
        //   this.NewStatusArray.push(modifiedData);
        // }
      }
      for (var i = 0; i < (data.user_matrix[0].status.length - 1); i++) {
        if (data.user_matrix[0].status[i].is_status_write === true) {
          // var status = {
          //   "status": data.user_matrix[0].status[i + 1].status_name
          // }
          this.statusArray.push(data.user_matrix[0].status[i + 1].status_name);
        }

      }
      console.log(this.statusArray_main_one_userMatrix);
      // console.log(this.statusArray);

      // for (var i = 0; i < this.finalStatus.length; i++) {
      //   var modifiedData = {
      //     "status_name": this.finalStatus[i].status,
      //     "status_id": this.finalStatus[i].status_id,
      //     "is_status_write": data.user_matrix[0].status[i].is_status_write,
      //     "is_status_read": data.user_matrix[0].status[i].is_status_read
      //   }
      //   this.NewStatusArray_final.push(modifiedData);
      // }
      // console.log(this.NewStatusArray_final);
      // console.log(this.statusArray);
      // for (var i = 0; i < this.NewStatusArray_final.length; i++) {
      //   var n = this.statusArray.includes(this.NewStatusArray_final[i].status_name);
      //   if (n) {
      //     this.NewStatusArray_final[i]["value"] = true
      //   } else {
      //     this.NewStatusArray_final[i]["value"] = false
      //   }
      // }
      // console.log(this.NewStatusArray_final);

      console.log(this.NewStatusArray);
      console.log(this.statusArray);
      if (this.statusArray.length === 0) {
        this.hidethisStatus = true;
        console.log("true");
      } else {
        this.hidethisStatus = false;
        console.log("false");
      }


      // for (var i = 0; i < this.finalStatus.length; i++) {
      //   if (this.finalStatus[i].status === this.selectedOrder.status) {
      //     this.finalStatus[i]["value2"] = true
      //     this.finalStatus[i + 1]["active"] = true
      //   } else { 
      //     this.finalStatus[i]["value2"] = false
      //   }
      // }
      // if (this.selectedOrder.status === "out_for_delivery" || this.selectedOrder.status === "delivered") {
      //   this.finalStatus[this.finalStatus.length - 1]["active"] = false;
      // } else {
      //   this.finalStatus[this.finalStatus.length - 1]["active"] = true;
      // }
      // console.log("Final Modified Array ", this.finalStatus);


      this.loader = false;
    }, err => {
      console.log(err);
      this.loader = false;
    });

    /*******Function For Wallmart Data *****/
    // getWalmartData(this.id) {
    //   this.service.walmartTableData(this.id).subscribe(data=>{
    //     alert(data)
    // let temp = [];
    // temp = data.data;
    // this.availableDetails = temp;
    //  console.log(this.availableSabhaDetails);

    //   });
    // }


    /*******Function For Wallmart Data End Here *****/
  }



  editedUser(user: string) {
    this.detailsData.editUser(this.newUser);
  }

  // getAccess(index, accessValue) {
  //   if(index == 0) {
  //     return 'none';
  //   } else {
  //     return 'all';
  //   }
  // }
  previuosOrderCount: any;
  public getOnlyThisOrder(call = null, id) {
    this.loader = true;
    console.log(localStorage.getItem('forCRMTEAM'));
    // setTimeout(() => {
    //   let ele: any = document.getElementById('status_filter')
    //   ele.value = ''
    // }, 500);
    // if (localStorage.getItem('forCRMTEAM') === 'true') {
    // var crmBody = {
    //   "channel_id": "7135d22e-557a-11eb-9b48-47de3ed79aee", // DEV
    //   // "channel_id": "0b2c77dc-5564-11eb-954b-73d89e99fb84", // PROD
    //   "role_id": localStorage.getItem('role_id')
    // }
    // this.service.getOrdersForCRM(crmBody).subscribe(crmOrderdetails => {
    //   console.log(crmOrderdetails);
    //   if (crmOrderdetails === null) {
    //     this.loader = false;
    //     this.message_display = true;
    //   } else {
    //     this.allOrdersList = crmOrderdetails[0].orders;
    //     console.log(this.allOrdersList);
    //     this.setPage(1);
    //   }
    // }, err => {
    //   this.loader = false;
    //   $.notify({
    //     icon: "add_alert",
    //     message: err.error.error_desc
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    // });
    // } else {
    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false
    }
    console.log(requestBody);

    // this.service.getListOfOrders().subscribe(res => {
    this.service.getOrdersByID(requestBody).subscribe(res => {
      console.log(res);

      // if(!this.previuosOrderCount){
      //   // if(this.buzzerStatus === "felse"){
      //     console.log(this.buzzerStatus)
      //   console.log("1111111111111111")
      //   this.previuosOrderCount = res[0].total_order_count;
      //   // }
      // }
      // else{
      //   if(res[0].total_order_count > this.previuosOrderCount){
      //     // if(this.buzzerStatus === "true"){
      //       console.log(this.buzzerStatus)
      //     console.log("22222222222");
      //     this.previuosOrderCount = res[0].total_order_count;
      //     for(let i=0;i<this.buzzerStatus.length;i++){
      //       if(this.buzzerStatus[i].store_code == this.checkNowAll[0].store_code && this.buzzerStatus[i].is_active ==true){
      //         console.log("testing sound",this.buzzerStatus[i].store_code,this.checkNowAll[0].store_code)
      //         this.playAudio();
      //         break;
      //       }
      //     }
      //     console.log(this.checkNowAll[0].store_code)
      //     // this.playAudio();
      //   // }
      //   }
      // }

      if (res === null) {
        this.loader = false;
        this.message_display = true;
        // var data = JSON.parse(localStorage.getItem('screensList'));
        // console.log(data.user_matrix[0].status);
        // var statusArray = [];
        // for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        //   if (data.user_matrix[0].status[i].is_status_write === true) {
        //     var status = {
        //       "status": data.user_matrix[0].status[i].status_name
        //     }
        //     statusArray.push(status);
        //   }
        // }
        // console.log(statusArray);
      } else {
        this.loader = false;
        this.message_display = false;


        this.allOrdersList = res[0].orders;
        console.log(this.allOrdersList);
        this.addingNewiteminOrder = res[0].orders;

        var finalData = _.map(this.addingNewiteminOrder, function (item) {
          if (item.order_no == id) {
            return item;
          }
          // return _.pick(item, "first_name", "last_name", "rating", "review")
        })
        finalData = finalData.filter((item) => {
          return item != null;
        });
        console.log(finalData);

        console.log(this.addingNewiteminOrder);

        this.checkNowAll = finalData;
        // this.checkNowAll = this.addingNewiteminOrder;
        this.totalOrders = res[0].total_order_count
        this.allOrdersList = this.checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        }
        // console.log(this.allOrdersList);
      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
    // }
  }

  public getAllOrdersList(call = null) {
    this.loader = true;
    console.log(localStorage.getItem('forCRMTEAM'));
    // setTimeout(() => {
    //   let ele: any = document.getElementById('status_filter')
    //   ele.value = ''
    // }, 500);
    // if (localStorage.getItem('forCRMTEAM') === 'true') {
    // var crmBody = {
    //   "channel_id": "7135d22e-557a-11eb-9b48-47de3ed79aee", // DEV
    //   // "channel_id": "0b2c77dc-5564-11eb-954b-73d89e99fb84", // PROD
    //   "role_id": localStorage.getItem('role_id')
    // }
    // this.service.getOrdersForCRM(crmBody).subscribe(crmOrderdetails => {
    //   console.log(crmOrderdetails);
    //   if (crmOrderdetails === null) {
    //     this.loader = false;
    //     this.message_display = true;
    //   } else {
    //     this.allOrdersList = crmOrderdetails[0].orders;
    //     console.log(this.allOrdersList);
    //     this.setPage(1);
    //   }
    // }, err => {
    //   this.loader = false;
    //   $.notify({
    //     icon: "add_alert",
    //     message: err.error.error_desc
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    // });
    // } else {
    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false
    }
    console.log(requestBody);

    // this.service.getListOfOrders().subscribe(res => {
    this.service.getOrdersByID(requestBody).subscribe(res => {
      console.log(res);

      // if(!this.previuosOrderCount){
      //   // if(this.buzzerStatus === "felse"){
      //     console.log(this.buzzerStatus)
      //   console.log("1111111111111111")
      //   this.previuosOrderCount = res[0].total_order_count;
      //   // }
      // }
      // else{
      //   if(res[0].total_order_count > this.previuosOrderCount){
      //     // if(this.buzzerStatus === "true"){
      //       console.log(this.buzzerStatus)
      //     console.log("22222222222");
      //     this.previuosOrderCount = res[0].total_order_count;
      //     for(let i=0;i<this.buzzerStatus.length;i++){
      //       if(this.buzzerStatus[i].store_code == this.checkNowAll[0].store_code && this.buzzerStatus[i].is_active ==true){
      //         console.log("testing sound",this.buzzerStatus[i].store_code,this.checkNowAll[0].store_code)
      //         this.playAudio();
      //         break;
      //       }
      //     }
      //     console.log(this.checkNowAll[0].store_code)
      //     // this.playAudio();
      //   // }
      //   }
      // }

      if (res === null) {
        this.loader = false;
        this.message_display = true;
        // var data = JSON.parse(localStorage.getItem('screensList'));
        // console.log(data.user_matrix[0].status);
        // var statusArray = [];
        // for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        //   if (data.user_matrix[0].status[i].is_status_write === true) {
        //     var status = {
        //       "status": data.user_matrix[0].status[i].status_name
        //     }
        //     statusArray.push(status);
        //   }
        // }
        // console.log(statusArray);
      } else {
        this.loader = false;
        this.message_display = false;
        // this.allOrdersList = res[0].orders;
        // console.log(this.allOrdersList);
        // this.addingNewiteminOrder = res[0].orders;
        // for(var i=0;i<this.addingNewiteminOrder.length; i++){
        //   this.addingNewiteminOrder[i]["channelId"]= i+1

        // }

        // console.log(this.addingNewiteminOrder);

        this.checkNowAll = res[0].orders;
        // this.checkNowAll = this.addingNewiteminOrder;
        this.totalOrders = res[0].total_order_count
        this.allOrdersList = this.checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        }
        // console.log(this.allOrdersList);
      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
    // }
  }

  filterOrdersByWhenReload(body) {
    var request = {
      "reqBody": body,
      "offset": this.offsetVal,
      "limit": this.shareThisCount
    }
    // setTimeout(() => {
    //   document.getElementById('heading2').click()
    //   let ele: any = document.getElementById('status_filter')
    //   ele.value = body.search
    // }, 500);
    this.service.searchAPI(request).subscribe(res => {
      // console.log(res);
      if (res === null) {
        this.loader = false;
        $('#status_filter').val("");
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        this.loader = false;
        this.pagedItems = res[0].orders;
        this.totalOrders = res[0].total_order_count;
        this.setPage(1);
      }
    }, err => {
      // console.log(err);
      this.loader = false;
    });
  }
  countPerPage(count) {
    console.log(count);
    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);
    this.getAllOrdersList();
    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {
    // this.loader = true;
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    // this.loader = false;
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick: string = null) {
    this.ShareThisSelectedList = 0;
    $('#selectall').prop("checked", false);
    // this.loader = true;
    if (page < 1 || page > this.pager.totalPages) {
      this.loader = false;
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;
    // get pager object from service
    if (numClick !== null && this.isFliterActive == false) {
      this.getAllOrdersList();
    } else if (numClick !== null && this.isFliterActive == true) {
      this.customeSearch();
    }
    this.pager = this.getPager(this.totalOrders, page);

    // get current page of items
    // this.pagedItems = this.allOrdersList.slice(0);      
    // this.loader = false;

  }

  // ----------------------------------------------//
  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr("status");
  }

  onSortClick1(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr1("created_at");
  }

  onSortClickOID(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrOID("order_no");
  }
  onSortClickCN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCusN("first_name", "last_name");

    // this.sortArrCN("last_name");

  }
  onSortClickPT(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCN("payment_method");

    // this.sortArrCN("last_name");

  }

  sortArr(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sortArr1(colName1: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName1].toLowerCase();
      b = b[colName1].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrOID(colName2: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName2].toLowerCase();
      b = b[colName2].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCN(colName3: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName3].toLowerCase();
      b = b[colName3].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCusN(colName3: any, x: any) {
    this.pagedItems.sort((a, b) => {
      a = (a[colName3] + ' ' + a[x]).toLowerCase();
      b = (b[colName3] + ' ' + b[x]).toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  // --------------------------------------------//



  // pageChanged(event) {
  //   this.config.currentPage = event;
  // }

  // onChangePage(pageOfItems: Array<any>) {
  //   this.loader = true;
  //   this.pageOfItems = pageOfItems;
  //   if (this.pageOfItems.length == 0) {
  //     this.first_id = 0;
  //     this.last_id = 0;
  //     this.loader = false;
  //   } else {
  //     this.first_id = this.pageOfItems[0].id
  //     this.last_id = this.pageOfItems[this.pageOfItems.length - 1].id;
  //     this.loader = false;
  //   }
  // }

  check_info_ID(e) {
    // console.log(e);
    var request = {
      "screen": "order ID",
      "column_name": "payment type",
      "search": e
    }
    this.loader = true;
    this.service.searchAPI(request).subscribe(res => {
      // console.log(res);
      this.loader = false;
      this.allOrdersList = res[0].orders;
    }, err => {
      // console.log(err);
      this.loader = false;
    });
  }
  check_info_date(d) {
    // console.log(d);

  }
  check_info_name(n) {
    // console.log(n);
    var request = {
      "screen": "order ID",
      "column_name": "customer Name",
      "search": n
    }
    this.loader = true;
    this.service.searchAPI(request).subscribe(res => {
      // console.log(res);
      this.loader = false;
      this.allOrdersList = res[0].orders;
    }, err => {
      // console.log(err);
      this.loader = false;
    });

  }
  check_info_payment_type(p) {
    // console.log(p);

  }
  check_info_status(s) {
    // console.log(s);

  }


  public viewValue(Id) {
    console.log("Selected Order", Id);
    this.selectedInfoForView = Id;
    this.router.navigate(['/view-orders-page', Id]);
    // this.detailsData.editUser(Id);
  }

  bulkStatus(Index_id, item_s, selected_previous_status) {
    console.log(item_s);
    console.log(this.allOrdersList);
    console.log(this.statusArray);
    console.log(this.statusArray_main_one_userMatrix);
    this.sendThis_item_s = item_s;
    this.sendThis_selected_previous_status = selected_previous_status;

    if (item_s.status_name === "cancelled") {
      this.showOnlyForCancel = true;
    } else {
      this.showOnlyForCancel = false;
    }
    // if (this.selectedAlldata.length === 0) {
    //   $.notify({
    //     icon: "add_alert",
    //     message: "Please Select the Order"
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    //   this.loader = false;
    //   this.selectedAlldata = [];
    //   console.log(this.selectedAlldata);
    //   this.getAllOrdersList();
    // } else {
    //   $('#bulk_status_update_id').modal();
    // }
  }
  reasonClose() {
    this.showThisMsgReasonRequired = false;
    $('#cancelation_reason').val("");
  }
  bulkStatusUpdate() {
    console.log(this.ShareThisSelectedList);
    $("#bulk_status_confirmation_open").modal("hide");
    if (this.sendThis_item_s.status_name === "placed") {
      var status = "Placed";
    }
    if (this.sendThis_item_s.status_name === "confirmed") {
      var status = "Confirmed";
    }
    if (this.sendThis_item_s.status_name === "pickup_confirmed") {
      var status = "Pickup Confirmed";
    }
    if (this.sendThis_item_s.status_name === "in_process") {
      var status = "In Process";
    }
    if (this.sendThis_item_s.status_name === "ready_to_dispatch") {
      var status = "Ready To Dispatch";
    }
    if (this.sendThis_item_s.status_name === "out_for_delivery") {
      var status = "Out For Delivery";
    }
    if (this.sendThis_item_s.status_name === "delivered") {
      var status = "Delivered";
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var status = "Cancelled";
    }
    console.log(this.sendThis_selected_previous_status);
    // console.log(this.selectedAlldata);
    console.log(this.pagedItems);
    var orderISsArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        if (this.pagedItems[i].status === this.sendThis_selected_previous_status) {
          orderISsArray.push(this.pagedItems[i].order_id);
        }
      }
    }
    console.log(orderISsArray);


    // console.log("seletec_data", this.selectedAlldata);
    console.log("access", this.statusArray_main_one_userMatrix);
    // console.log(this.statusArray_main_one_userMatrix_include_key);
    // for (var i = 0; i < this.pagedItems.length; i++) {
    //   if ($('#dynamicID' + i).prop("checked") === true) {
    //     var n = this.statusArray_main_one_userMatrix.includes(this.pagedItems[i].status);
    //     if (n) {
    //       this.arraychecking.push(this.pagedItems[i])
    //     } else {
    //       this.arraychecking = [];
    //     }
    //   }
    // }
    // console.log(this.arraychecking);
    console.log(this.sendThis_item_s.status_id);


    if (this.sendThis_item_s.status_name === "cancelled") {
      var cancelationReason = $('#cancelation_reason').val();
      if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
        this.showThisMsgReasonRequired = true;
      } else {
        this.showThisMsgReasonRequired = false;
        console.log("You can");
        var NeworderISsArray = [];
        var NeworderISsArrayNew = [];
        var camparingArray = new Array();
        camparingArray[0] = "cancelled",
          camparingArray[1] = "out_for_delivery",
          camparingArray[2] = "delivered",
          camparingArray[3] = "payment_failed"
        console.log(camparingArray);
        for (var i = 0; i < this.pagedItems.length; i++) {
          if ($('#dynamicID' + i).prop("checked") === true) {
            var n = camparingArray.includes(this.pagedItems[i].status);
            if (n) {
              NeworderISsArrayNew.push(this.pagedItems[i].order_id);
            } else {
              // var NeworderISsArray = [];
              NeworderISsArray.push(this.pagedItems[i].order_id);
            }
          }
        }
        console.log(NeworderISsArray);
        if (NeworderISsArray.length === 0) {
          // $("#bulk_status_confirmation_open").modal("hide");
          $.notify({
            icon: "add_alert",
            message: "You Can't change Into" + " " + status + " " + "Status"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.loader = false;
          this.selectedAlldata = [];

          NeworderISsArray = [];
          this.arraychecking = [];
          console.log(this.selectedAlldata);
        } else {
          var cancelationReason = $('#cancelation_reason').val();
          console.log(cancelationReason);
          // if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
          //   this.showThisMsgReasonRequired = true;
          // } else {
          // this.showThisMsgReasonRequired = false;
          console.log(cancelationReason);
          console.log(NeworderISsArray);
          var reqCancel = {
            "status_id": this.sendThis_item_s.status_id,
            "order_id": NeworderISsArray,
            "reason": cancelationReason
          }
          console.log(reqCancel);
          this.loader = true;
          this.bulk_update_waiting = true;
          this.service.updateBulk(reqCancel).then(async res => {
            console.log(res);
            this.updateMesg = res;
            await this.updateMesg;
            $('#cancelation_reason').val("");
            // $("#bulk_status_confirmation_open").modal("hide");
            $('#selectall').prop("checked", false);
            this.bulk_update_waiting = false;
            this.loader = false;
            $.notify({
              icon: "add_alert",
              message: this.updateMesg.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];

            NeworderISsArray = [];
            this.arraychecking = [];
            console.log(this.selectedAlldata);
            this.showThisMsgReasonRequired = false;
            this.getAllOrdersList();
          }).catch(err => {
            console.log(err);
            this.selectedAlldata = [];
            NeworderISsArray = [];
            console.log(this.selectedAlldata);
            this.loader = false;
            // $("#bulk_status_confirmation_open").modal("hide");
            $('#selectall').prop("checked", false);
            $.notify({
              icon: "add_alert",
              message: err.error.error_desc
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];
            this.arraychecking = [];
          });
        }
      }
    } else {
      if (orderISsArray.length === 0) {
        $.notify({
          icon: "add_alert",
          message: "You Can't change Into" + " " + status + " " + "Status"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
        this.selectedAlldata = [];

        NeworderISsArray = [];
        this.arraychecking = [];
        console.log(this.selectedAlldata);
        // $("#bulk_status_confirmation_open").modal("hide");
      } else {

        var req = {
          "status_id": this.sendThis_item_s.status_id,
          "order_id": orderISsArray,
          "reason": null
        }
        console.log(req);
        this.loader = true;
        this.bulk_update_waiting = true;
        this.service.updateBulk(req).then(res => {
          console.log(res);
          this.updateMesg = res;
          // await this.updateMesg;
          // $("#bulk_status_confirmation_open").modal("hide");
          $('#selectall').prop("checked", false);
          this.bulk_update_waiting = false;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.updateMesg.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          NeworderISsArray = [];
          this.selectedAlldata = [];
          this.arraychecking = [];
          console.log(this.selectedAlldata);
          this.getAllOrdersList();
        }).catch(err => {
          console.log(err);
          NeworderISsArray = [];
          this.selectedAlldata = [];

          this.arraychecking = [];
          console.log(this.selectedAlldata);
          // $("#bulk_status_confirmation_open").modal("hide");
          $('#selectall').prop("checked", false);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: err.error.error_desc
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }
  }

  closethisNow() {
    // $("#remove_selecte_store").modal("hide");
    $("#bulk_status_confirmation_close").trigger("click");
  }
  // ngAfterViewInit() {

  //   this.onPrintInvoice("item");
  // }


  onPrintInvoice(item) {
    console.log(item);
    this.service.getInvoiceData(item.order_id).subscribe(res => {
      console.log(res);
      this.testingNow = res;
      this.ref.detectChanges();
      var dataInfo = document.getElementById('elem');
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(dataInfo.innerHTML); WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();

    }, err => {
      console.log(err);
      window.close();
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
  }

  handleAsideClick(event: Event) {
    event.stopPropagation();
  }
  isSearchSuggestion: boolean;
  searchedProducts: any;
  searchLoader: boolean;
  search(searchText, event) {
    this.loader = true;
    if (event.keyCode == 13) {
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    if (searchText.length < 3) {
      this.searchedProducts = '';
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    this.searchLoader = true;
    // this.sharedService.sendLoaderStatus(true);

    let requestBody = {
      "param": {
        "type": "search",//search or category
        "productName": searchText,// only if search
        // "store_id": this.sharethisInfo.store_id,
        "store_id": localStorage.getItem('plant_code_add_product'),
        "categoryId": "",
        "sort_by": '',
        "pageNumber": "",
        "pageCount": "",
        "filters": {
        }
      }
    }
    console.log(requestBody);
    this.sharedService.productSearch(requestBody).subscribe(data => {
      console.log(data);
      this.searchLoader = false;
      this.searchedProducts = data[0].products;
      console.log(this.searchedProducts);
      this.isSearchSuggestion = true;
      this.loader = false;
    }, err => {
      console.log(err);
      this.loader = false;
    });
  }

  skuCode: any;
  searchSelect(product) {
    this.isSearchSuggestion = false;
    this.searchkey = product.name + '' + product.sku
    this.skuCode = product.sku
  }

  async getAllStores() {
    (await this.service.getAllStoresIds()).subscribe(res => {
      this.storedataresponse = res;
      this.allStoresIds = res;

      this.storeCodes = res;
      this.storeCodeDataNew = this.storeCodes.all_store_code;
      console.log(this.storeCodeDataNew);
    }, err => {
      console.log(err);
    })
    // anjai
    // this.service.getAllStoresIds().then((data) => {
    //   console.log(data);
    //   this.storedataresponse = data;
    //   this.allStoresIds = data;

    //   this.storeCodes = this.storedataresponse;
    //   this.storeCodeDataNew = this.storeCodes;
    //   console.log(this.storeCodeDataNew);
    // });
  }
  productItem: any;
  // storeSelect:any;
  // status:any;
  // reason:any
  // quantity:any;
  bulkProduck(form) {
    console.log(form.value)
    this.isSubmit = true
    if (form.valid) {
      this.loader = true
      var request = {
        "sku": form.value.skuCode || null,
        "store_code": form.value.storeSelect || null,
        "status": form.value.status || null,
        "reason": form.value.reason || null,
        "quantity": +(form.value.quantity) || null,
      }
      this.service.productRemovel(request).subscribe((data) => {
        this.loader = false
        console.log(data);
        this.productItem = data;
        if (data === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.productItem.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      }, err => {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No orders found with this SKU"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      })
      console.log(request)
    }
  }

  walletPopup() {
    let requestBody = {
      "order_id": this.selectedOrderItem.order_id,
      "wallet_amount": this.selectedOrderItem.wallet_refund_amount
    }
    this.loader = true;
    this.sharedService.walletRevasal(requestBody).subscribe((data: any) => {
      this.walletRevalsal = data
      this.loader = false;
      console.log(data)
      if (data.message) {
        document.getElementById("toggleWalletUp").click();
        this.selectedOrderItem = ''
      } else {
        $.notify({
          icon: "add_alert",
          message: "Wallet amount is not present"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      }
    }, erro => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Wallet amount is not present"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    })
  }

  selectedOrderItem
  refundWallet(item) {
    this.selectedOrderItem = item;
    console.log(item)
    document.getElementById("toggleWalletUp").click()
  }
  fileURL: any;
  bulkProductPrint() {
    let orderIds = []
    this.selectedAlldata.forEach(item => {
      orderIds.push(item.order_id)
    })
    this.loader = true;
    if (orderIds.length) {
      let requestBody = {
        "order_id": orderIds
      }
      this.sharedService.bulkProdukPrint(requestBody).subscribe((data: any) => {
        console.log(data)
        this.loader = false;
        var blob = new Blob([data], { type: 'application/pdf' }); //this make the magic
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
        // var file = new Blob([data], { type: 'text' })
        //  var fileURL = URL.createObjectURL(file);

        //  window.open(fileURL); 
        //  var a         = document.createElement('a');
        //  a.href        = fileURL; 
        // //  a.target      = '_blank';
        //  a.download    = 'bill.pdf';
        //  document.body.appendChild(a);
        //  a.click();
      })
    } else {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Select"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }


  }
  playAudio() {
    let audio = new Audio();
    // audio.src = "src/assets/sounds/alarm.wav";
    audio.src = "assets/sounds/buzzer.wav"
    audio.load();
    audio.play();
  }

  getOrdersbyselectedOMSID(selectedOMSData) {
    console.log(selectedOMSData);
    this.selectedOMSIDorderID = [];
    console.log(this.pagedItems);

    for (var i = 0; i < this.pagedItems.length; i++) {
      if (selectedOMSData.order_no == this.pagedItems[i].order_no) {
        var obj = {
          selectedChannelID: this.pagedItems[i].channelId
        }
        this.selectedOMSIDorderID.push(obj)
      }
    }
    console.log(this.selectedOMSIDorderID);
    this.selectedOMSIDorderIDfinal = this.selectedOMSIDorderID[0].selectedChannelID

  }


  // getAllBuzzerStores(){
  //   this.service.getbuzzer().subscribe((data)=>{
  //     console.log("buzzer",data)
  //     this.buzzerStatus = data[0].buzzer_status
  //     console.log(this.buzzerStatus)
  //   })
  // }

  // selectedGroupByChannelID() {
  //   this.router.navigate(['/app-group-by-channel-id', { page: "fromOrdersPage" }]);
  // }

  checkorderstatus() {
    this.orderzipCode
    this.orderEmail
    this.returnOrderIdd

    console.log(this.orderzipCode);
    console.log(this.orderEmail);
    console.log(this.returnOrderIdd);
    var requestbody = {
      "order_no": this.returnOrderIdd,
      "email": this.orderEmail,
      "pincode": this.orderzipCode
    }

    this.service.searchcheckorderstatus(requestbody).subscribe(res => {
      console.log(res);

      this.itemsData = res[0];

      this.returnorderstatus = true;



      this.Customerbillingaddresses = this.itemsData.addresses[0].billing_address[0]
      this.Customershippingaddresses = this.itemsData.addresses[0].shipping_address[0]
      console.log(this.Customerbillingaddresses);
      console.log(this.Customershippingaddresses);


      this.shipmentOptions();
      this.returnreason();



    }, err => {
      console.log(err);
      this.showThis45DaysError = err.error.error_desc;
      this.returnorderstatus = false;

    })

    // searchcheckorderstatus

  }

  returnreason() {
    this.service.getallreason().subscribe(res => {
      console.log(res);
      this.totalreasons = res;
    }, err => {
      console.log(err);

    })
  }
  returnreasonsselect(selectedData) {
    console.log(selectedData);
    this.selectedReturenReason = selectedData;

  }

  shipmentOptions() {
    this.service.ordershipmentoptions().subscribe(res => {
      console.log(res);
      this.shipmentoptionsData = res;
      this.shipmentOptionsInfo = this.shipmentoptionsData.shipment_option
      console.log(this.shipmentOptionsInfo);
    }, err => {
      console.log(err);

    })
  }
  shipmentoptionselect(selectedData) {
    console.log(selectedData);
    this.selectedShipperName = selectedData;
    this.shipmentservicetypes();
  }

  shipmentservicetypes() {

    var reqbody = {
      "shipping_option": this.selectedShipperName
    }

    this.service.gethipmentservicetypes(reqbody).subscribe(res => {
      console.log(res);
      this.shipperServiceData = res;
      this.shipperServiceInfo = this.shipperServiceData.shipping_service_type
    }, err => {
      console.log(err);
    });
  }

  shipperServiceSelectedInfo(selectedData) {
    console.log(selectedData);
    this.selectedShipperServiceData = selectedData;
  }
  selectAllItems(selectedOrder) {
    console.log(selectedOrder);
    this.selectedOrderDetails = selectedOrder;
    var checkboxesSelectedOrnot = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        checkboxesSelectedOrnot.push(false);
      }
      else {
        checkboxesSelectedOrnot.push(true);
      }
    }
    console.log(checkboxesSelectedOrnot);
    if (checkboxesSelectedOrnot.includes(false)) {
      this.showthisitemafterclick = false;
    } else {
      this.showthisitemafterclick = true;
    }
    console.log(this.showthisitemafterclick);

  }


  returnAndrefundCheck(event) {
    console.log(event.target.checked);

    if (event.target.checked == true) {
      $("#returnAndreplacement").attr("disabled", true);
      this.returnAndrefundData = true;
      this.returnAndreplacementData = false;

    } else {
      this.returnAndrefundData = false;
      this.returnAndreplacementData = true;

      $("#returnAndreplacement").removeAttr("disabled");
    }

  }
  returnAndreplacementCheck(event) {
    console.log(event.target.checked);
    if (event.target.checked == true) {
      $("#returnAndrefund").attr("disabled", true);
      this.returnAndreplacementData = true;
      this.returnAndrefundData = false;

    } else {
      this.returnAndreplacementData = false;
      this.returnAndrefundData = true;

      $("#returnAndrefund").removeAttr("disabled");
    }
  }
  InitiateReturn() {

    console.log(this.itemsData);
    console.log(this.selectedOrderDetails);


    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_part_no = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxesOrder_part_no.push(this.itemsData.items[i].order_part_no)
      }
      else {
        console.log("no");
      }
    }
    console.log(finalSelectedCheckBoxes);
    console.log(finalSelectedCheckBoxesOrder_part_no);




    var requestBody = {
      "order_id": this.itemsData.order_id,
      "order_parts": finalSelectedCheckBoxesOrder_part_no,
      "item_id": finalSelectedCheckBoxes,
      "return_reason": this.selectedReturenReason,
      "return_refund": this.returnAndrefundData,
      "return_replacement": this.returnAndreplacementData,
      "channel_name": this.itemsData.channel,
      "shipment_option": this.selectedShipperName,
      "shipping_service_type": this.selectedShipperServiceData
    }

    console.log(requestBody);

    if (requestBody.return_reason == undefined || requestBody.return_refund == undefined || requestBody.return_replacement == undefined || requestBody.shipment_option == undefined || requestBody.shipping_service_type == undefined || requestBody.item_id.length == 0) {
      this.proceedToInitiatedReturn = true;
      // console.log(requestBody);
      console.log("please select the mandatory");
      $.notify({
        icon: "add_alert",
        message: "Please select all"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });

    } else {
      this.proceedToInitiatedReturn = false;
      console.log("all selected");

      console.log(requestBody);
      this.service.initiatereturnorders(requestBody).subscribe(res => {
        console.log(res);
        this.router.navigate(['/orders']);
        if (requestBody.return_refund == true) {
          // navigate to return and refund component
          $.notify({
            icon: "add_alert",
            message: "Order Return and Refund happen successfully"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          // navigate to return and replacement component
          $.notify({
            icon: "add_alert",
            message: "Order Return and Replacement happen successfully"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      }, err => {
        console.log(err);
      })

    }



  }

}



