import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { Subscription } from 'rxjs';
declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: '/orders', title: 'Orders', icon: 'list', class: '' },
  { path: '/customers', title: 'Customers', icon: 'supervised_user_circle', class: '' },
  { path: '/stores', title: 'Stores', icon: 'store', class: '' },
  { path: '/cart-info', title: 'Cart', icon: 'shopping_cart', class: '' },
  { path: '/order-rating-and-review', title: 'Order Rating & Review', icon: 'star_rate', class: '' },
  { path: '/returns-and-refunds', title: 'Returns & Refunds', icon: 'remove_shopping_cart', class: '' },
  { path: '/subscribers', title: 'Subscription', icon: 'mark_email_read', class: '' },
  // { path: '/serviceability', title: 'Serviceability', icon: 'storefront', class: '' },
  { path: '/configurations', title: 'Configurations', icon: 'storefront', class: '' },
  { path: '/users-list', title: 'UsersList', icon: 'supervised_user_circle', class: '' },
  // { path: '/inventory', title: 'InventoryList', icon: 'inventory', class: '' },
  { path: '/shopify-stride-rite', title: 'ShopifyStrideRite', icon: 'inventory', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public sidebarShow: boolean = false;
  menuItems: any[];
  selectedScrennsList: any;
  showThisScreenWithPermissions: any;

  permissionForOnlyThisScreenDashboard = [];
  permissionForOnlyThisScreenOrders = [];
  permissionForOnlyThisScreenInventory = [];
  permissionForOnlyThisScreenCatalouge = [];
  permissionForOnlyThisScreenReturns_and_Refunds = [];
  permissionForOnlyThisScreenSubscription = [];
  permissionForOnlyThisScreenConfigurations = [];
  permissionForOnlyThisScreenUser_Management = [];
  permissionForOnlyThisScreenCustomers = [];
  permissionForOnlyThisScreencarts = [];
  permissionForOnlyThisScreenReports = [];
  permissionForOnlyThisGiftCard = [];
  ThisScreenDashboard: any;
  ThisScreencarts: any;
  ThisScreenCustomers: any;
  ThisScreenUser_Management: any;
  ThisScreenConfigurations: any;
  ThisScreenSubscription: any;
  ThisScreenReturns_and_Refunds: any;
  ThisScreenOrder_Rating_and_Review: any;
  ThisScreenStores_Management: any;
  ThisScreenOrders: any;
  ThisScreenReports: any;
  ThisScreenGiftCard: any;
  onlyForDashboard: any;
  ThisScreenCoupons: any;
  ThisScreenInventory: any;
  ThisScreenCatalouge: any;
  permissionForOnlyThisScreenPaymentFaild = [];
  ThisPaymentFaild: any;
  clickEventsubscription:Subscription;
  constructor( private router: Router,private service:SharedServiceService) {
    this.clickEventsubscription=    this.service.getClickEvent().subscribe(()=>{
      this.incrementCount();
      // console.log( this.incrementCount())
      

    
 
      })
    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));
    console.log(this.showThisScreenWithPermissions);

    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      // ------------------------- VIDA Screens ADMIN ----------------------------- //
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Dashboard") {
        var forOrders1 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenDashboard.push(forOrders1);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders2 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenOrders.push(forOrders2);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "User Management") {
        var forOrders8 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenUser_Management.push(forOrders8);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Reports") {
        var forOrders11 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenReports.push(forOrders11);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Inventory") {
        var forOrders3 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenInventory.push(forOrders3);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Catalouge") {
        var forOrders4 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenCatalouge.push(forOrders4);
      }

      if (this.showThisScreenWithPermissions.screens[i].screen_name === "GiftCard") {
        var forOrders12 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisGiftCard.push(forOrders12);
      }
      console.log(this.permissionForOnlyThisGiftCard);
      // --------------------------- VIDA Screens ADMIN --------------------------- //


      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Returns and Refunds") {
        var forOrders5 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        } 
        this.permissionForOnlyThisScreenReturns_and_Refunds.push(forOrders5);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Subscription") {
        var forOrders6 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenSubscription.push(forOrders6);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Configurations") {
        var forOrders7 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenConfigurations.push(forOrders7);
      }

      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Customers") {
        var forOrders9 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenCustomers.push(forOrders9);
      }
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "carts") {
        var forOrders10 = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreencarts.push(forOrders10);
      }
     
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Payment Failed") {
        var forOrdersNew = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreenPaymentFaild.push(forOrdersNew);
      }
    }
    console.log(this.permissionForOnlyThisScreenDashboard[0]);
    this.ThisScreenDashboard = this.permissionForOnlyThisScreenDashboard[0];
    this.ThisScreenOrders = this.permissionForOnlyThisScreenOrders[0];
    this.ThisScreenInventory = this.permissionForOnlyThisScreenInventory[0];
    this.ThisScreenCatalouge = this.permissionForOnlyThisScreenCatalouge[0];
    this.ThisScreenReports = this.permissionForOnlyThisScreenReports[0];
    this.ThisScreenGiftCard = this.permissionForOnlyThisGiftCard[0];
    this.ThisScreenUser_Management = this.permissionForOnlyThisScreenUser_Management[0];

    this.ThisScreenReturns_and_Refunds = this.permissionForOnlyThisScreenReturns_and_Refunds[0];
    this.ThisScreenSubscription = this.permissionForOnlyThisScreenSubscription[0];
    this.ThisScreenConfigurations = this.permissionForOnlyThisScreenConfigurations[0];
    this.ThisScreenCustomers = this.permissionForOnlyThisScreenCustomers[0];
    this.ThisScreencarts = this.permissionForOnlyThisScreencarts[0];

    this.ThisPaymentFaild = this.permissionForOnlyThisScreenPaymentFaild[0];
    // console.log(this.ThisScreenDashboard);
    this.onlyForDashboard = localStorage.getItem('dashboard');
    console.log(this.onlyForDashboard);
  }
  incrementCount(){
    this.sidebarShow = false;
//     $(".sidebar-opener").mouseover(function() {
//     $('.sidebar-slider').show();
// }).mouseout(function(){
//     $('.sidebar-slider').hide();
// });
    // $(".sidebar-slider").animate({width:'toggle'},10);
    // $('.sidebar-slider').animate({ right: 'toggle' });
    // $('.sidebar-close').animate({ right: 'toggle' });
    // this.sidebarShow = false;
    
   
      // $('.sidebar-slider').removeClass('sidebar-slider');
     
  
  // console.log(this.count++);
  
  
  }
  
//   public logOut() {
//     this.router.navigate(['/']);
//     localStorage.removeItem('role');
//     localStorage.removeItem('role_id');
//     localStorage.removeItem('screensList');
//     localStorage.removeItem('dashboard');
//     localStorage.removeItem('forCRMTEAM');
//     localStorage.removeItem('token');
//     localStorage.removeItem('email');
//     localStorage.removeItem('storeId');
//     localStorage.removeItem('slotDate');
//     localStorage.removeItem('slotId');
//     localStorage.removeItem('plant_code');
//     localStorage.removeItem('plant_code_add_product');
//     localStorage.removeItem('User_Email');
    
// }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
