import {
  Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef,
  HostListener, ElementRef, ChangeDetectionStrategy
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { from } from 'rxjs';
import { data } from 'jquery';
import * as moment from 'moment';
import { replaceAll } from 'chartist';
declare var $: any;
@Component({
  selector: 'app-ebay',
  templateUrl: './ebay.component.html',
  styleUrls: ['./ebay.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EbayComponent implements OnInit {


  buttonHover = ""
  filterOrders = "";
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForView: any;
  selectedInfoForEdit: any;
  finalData: { order_id: string; order_date: string; delivery_date: string; customer_name: string; payment_status: string; order_type: string; status: string; }[];
  selectedAlldata = [];
  selectedAlldataArray = [];
  allOrdersList: any;
  loader: boolean;
  message_display: boolean = false;
  showThisScreenWithPermissions: any;
  permissionForOnlyThisScreen = [];
  allPermissionForOnlyThisScreen: any;
  statustotalList: any;
  custome_download: FormGroup;
  productBulkRemoval: FormGroup;
  custome_search: FormGroup;
  submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  pageOfItems: any;
  first_id: any;
  last_id: any;
  totalElements: any;
  forFilterUse: any;
  fullfillData: any;
  config: any;
  id: any;
  pager: any = {};
  pagedItems = [];
  finalStatus: any;
  status_with_time: any;
  statusTimeStampArray = [];
  finalArray: any;
  modifiedStatusArray: any;
  statusArray = [];
  updateMesg: any;
  NewStatusArray = [];
  hidethisStatus: boolean;
  NewStatusArray_final = [];
  statusArray_main_one_userMatrix = [];
  currectArray = [];
  statusArray_main_one_userMatrix_include_key = [];
  thisIsMatched: boolean;
  arraychecking = [];
  sendThis_item_s: any;
  sendThis_selected_previous_status: any;
  showThisSelectMSG: boolean;
  showOnlyForCancel: boolean;
  showThisMsgReasonRequired: boolean;
  pageCount = [];
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  onlyForDashboard: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  testingNow: any;
  shareThisCount: any = 50;
  offsetVal: number = 0;
  totalOrders: any;
  public isFliterActive: boolean = false;
  public bulk_update_waiting: boolean = false;
  searchkey: any
  isSubmit: boolean;
  checkNowAll: any;
  walletRevalsal: any;
  public moment: any = moment;
  newUser: any;
  user: string;
  initialLoad: boolean = true
  deliveryDate: any;
  testing: any;
  delivery = ('#delivery_date'.replace('-', '/'));
  buzzerStatus: any;
  storeCodes: any;
  storedataresponse: any;
  storeCodeDataNew: any;
  allStoresIds: any;
  addingNewiteminOrder: any;
  selectedOMSIDorderID: any;
  selectedOMSIDorderIDfinal: any;
  fromGBCIDInfo: any;
  fromGBCID: any;
  moveToAllOrdersList: boolean = false;
  downloadThisFilteredData: any;
  selectedSearchInfoIsSearchHappen: boolean;
  orderSearchItemsList: any;
  orderIdValueInOrdersPage: any;
  channelIdValueInOrdersPage: any;
  channelValueInOrdersPage: any;
  emailValueInOrdersPage: any;
  fromDateValueInOrdersPage: any;
  toDateValueInOrdersPage: any;
  customerNameValueInOrdersPage: any;
  statusValueInOrdersPage: any;
  inOrdersPageSearchHappenOrNot: any;
  localhostSearchDataIsPresent: boolean;
  resetClicked = false;
  selectedPageNumber: number = 1;
  selectedDataOffset: number = 0;
  noDatafoundOnSearch: boolean = false;
  selectedPageNumberViewBack: number = 1;
  selectedPageItemsCount: number;
  constructor(private ref: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder,
    private exportService: ExportService, private service: SharedServiceService, public sharedService: SharedServiceService,
    private detailsData: ExportService, private route: ActivatedRoute) {


    if (localStorage.getItem("InEbayPageSelectedSearchItems")) {
      this.orderSearchItemsList = JSON.parse(localStorage.getItem("InEbayPageSelectedSearchItems"));

      $('#order_id_filter').val(this.orderSearchItemsList.orderID);
      $('#chanenl_with_filter').val(this.orderSearchItemsList.channelID);
      $('#chanenl_name_with_filter').val(this.orderSearchItemsList.channelName);
      $('#customer_mail_id_filter').val(this.orderSearchItemsList.email);
      $('#order_date_id_filter').val(this.orderSearchItemsList.fromDate);
      $('#order_todate_id_filter').val(this.orderSearchItemsList.todate);
      $('#customer_name_id_filter').val(this.orderSearchItemsList.customerName);
      $('#status_filter').val(this.orderSearchItemsList.status);

      this.orderIdValueInOrdersPage = this.orderSearchItemsList.orderID;
      this.channelIdValueInOrdersPage = this.orderSearchItemsList.channelID;
      this.channelValueInOrdersPage = this.orderSearchItemsList.channelName;
      this.emailValueInOrdersPage = this.orderSearchItemsList.email;
      this.fromDateValueInOrdersPage = this.orderSearchItemsList.fromDate;
      this.toDateValueInOrdersPage = this.orderSearchItemsList.todate;
      this.customerNameValueInOrdersPage = this.orderSearchItemsList.customerName;
      this.statusValueInOrdersPage = this.orderSearchItemsList.status;
    } else {
      this.channelValueInOrdersPage = "Channel...";
      this.statusValueInOrdersPage = "Status...";
    }



    this.fromGBCID = this.route.params.subscribe(params => {

      this.fromGBCIDInfo = params;
      if (this.fromGBCIDInfo.order_no) {

        this.getOnlyThisOrder("pageLoad", this.fromGBCIDInfo.order_no);
        this.moveToAllOrdersList = true;
      } else {

        if (localStorage.getItem('ebayOrdersPageSearchInfo')) {
          this.inOrdersPageSearchHappenOrNot = JSON.parse(localStorage.getItem('ebayOrdersPageSearchInfo'));

          if (this.inOrdersPageSearchHappenOrNot.searchHappen == true) {
            this.localhostSearchDataIsPresent = true;
          } else {
            this.localhostSearchDataIsPresent = false;
          }
        } else {
          this.localhostSearchDataIsPresent = false;
        }

        if (this.localhostSearchDataIsPresent == true) {
          this.inOrdersPageSearchHappenOrNot = JSON.parse(localStorage.getItem('ebayOrdersPageSearchInfo'));

          this.customeSearch(this.inOrdersPageSearchHappenOrNot.selectedOffsetInfo, this.inOrdersPageSearchHappenOrNot.selectedPageInfo, null);
        } else {
          this.getAllOrdersList("pageLoad");
          this.route.queryParams.subscribe(queryParams => {

            if (Object.keys(this.route.snapshot.queryParams).length == 0 && !this.initialLoad) {
              this.getAllOrdersList("pageLoad");
            }
          });
          if (Object.keys(this.route.snapshot.queryParams).length != 0 && this.initialLoad) {
            this.initialLoad = false;
            this.filterOrdersByWhenReload(this.route.snapshot.queryParams)
          } else {
            this.initialLoad = false;
            this.getAllOrdersList("pageLoad");
          }

        }
      }
    });


    this.onlyForDashboard = localStorage.getItem('dashboard');

    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));


    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }

    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];


    this.custome_download = this.formBuilder.group({
      from_date: [''],
      to_date: [''],
      status_id: [0]
    });

    this.productBulkRemoval = this.formBuilder.group({
      skuCode: ['', Validators.required],
      storeSelect: ['default', Validators.required],
      status: ['', Validators.required],
      reason: ['', Validators.required],
      quantity: ['']


    });


    this.custome_search = this.formBuilder.group({
      order_id: [''],
      order_date: [''],
      customer_name: [''],
      payment_type: [''],
      order_status: [''],
      mobile_number: [''],
      delivery_date: [''],

    });
  }


  checkDateValidity() {
    const fromDate = moment(this.fromDateValueInOrdersPage, 'YYYY-MM-DD');
    const toDate = moment(this.toDateValueInOrdersPage, 'YYYY-MM-DD');

    // if (fromDate.isAfter(toDate)) {
    //   $.notify({
    //     icon: "add_alert",
    //     message: "To Date should be greater than or equal to From Date"
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    // }
  }


  get a() { return this.custome_download.controls; }
  get b() { return this.custome_search.controls; }

  public selectedStatusInfor(v) {

    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#mobile_number').val("");
    $('#delivery_date').val("");




  }
  public customeExport() {
    this.loader = true;
    this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }


    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);

    if ((this.to_date - this.from_date) >= 0) {

      this.service.downloadCustomeorders(this.custome_download.value).subscribe(res => {

        if (res === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.customeData = res;
          // from_date
          let date = "All order";
          if (this.a.from_date.value && this.a.to_date.value) {
            date = `Order Export - ${moment(this.a.from_date.value).format("DD/MM/YYYY")} -${moment(this.a.to_date.value).format("DD/MM/YYYY")}`;
          }
          this.exportService.exportExcel(this.customeData[0].orderfilter, date);
          this.loader = false;
          $('#from_date').val("");
          $('#to_date').val("");
          $('#selectedStatus').val("");
          this.submitted = false;
          this.custome_download.reset();
        }
      }, err => {

        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: err.error.error_desc
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      });
    } else {
      $('#to_date').val("");

      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }

  }
  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    $('#selectedStatus').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    this.custome_download.reset();
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }
  bulkProduct() {
    $('#sku').val("");
    $('#store_code').val("");
    $('#status').val("");
    $('#reason').val("");
    $('#quantity').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }

  }
  fromDateEvent(val) {
    if (typeof (val) != 'string') {
      if (val.target.value != null || val.target.value != undefined || val.target.value != "") {
        $('#' + val.srcElement.id).addClass("postSelect");
        $('#' + val.srcElement.id).removeClass("preSelect");
      } else {
        $('#' + val.srcElement.id).addClass("preSelect");
        $('#' + val.srcElement.id).removeClass("postSelect");
      }
    } else {
      $('#' + val).addClass("preSelect");
      $('#' + val).removeClass("postSelect");
    }

  }

  onlyForCSS(val) {
    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "chanenl_name_with_filter" || val.srcElement.id == 'status_filter') {
          if (val.target.value != null && val.target.value == "Channel..." || val.target.value == "Status...") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
  }
  public customeSearch(offset = null, call, val) {
    //   if (!this.orderIdValueInOrdersPage && !this.channelIdValueInOrdersPage && 
    //     this.channelValueInOrdersPage === 'Channel...' && !this.emailValueInOrdersPage && 
    //     !this.fromDateValueInOrdersPage && !this.toDateValueInOrdersPage && 
    //     !this.customerNameValueInOrdersPage && this.statusValueInOrdersPage === 'Status...' && !this.resetClicked) {
    //   $.notify({
    //     icon: "add_alert",
    //     message:"Please Enter Input"
    //   }, {
    //     type: 'info',
    //     timer: 1000,
    //     placement: {
    //       from: 'top',
    //       align: 'center'
    //     }
    //   });
    // }
    //   
    //   if (val) {
    //     if (typeof (val) != 'string') {
    //       if (val.srcElement.id == "chanenl_name_with_filter" || val.srcElement.id == 'status_filter') {
    //         if (val.target.value != null && val.target.value == "Channel..." || val.target.value == "Status...") {
    //           $('#' + val.srcElement.id).addClass("preSelect");
    //           $('#' + val.srcElement.id).removeClass("postSelect");
    //         } else {
    //           $('#' + val.srcElement.id).addClass("postSelect");
    //           $('#' + val.srcElement.id).removeClass("preSelect");
    //         }
    //       }
    //     } else {
    //       $('#' + val).addClass("preSelect");
    //       $('#' + val).removeClass("postSelect");
    //     }
    //   }
    setTimeout(() => {
      this.loader = true;
    }, 900);
    this.isFliterActive = true;
    if (offset !== null) {
      this.offsetVal = offset
    }
    var fromDateSelectedOne = $('#order_date_id_filter').val() || null
    var toDateSeletedOne = $('#order_todate_id_filter').val() || null;

    if (fromDateSelectedOne == null && toDateSeletedOne == null) {
      var fromDateToDate = null;
    } else {
      fromDateToDate = fromDateSelectedOne + "," + toDateSeletedOne
    }


    var orderIDInfo = $('#order_id_filter').val();


    this.inOrdersPageSearchHappenOrNot = JSON.parse(localStorage.getItem('ebayOrdersPageSearchInfo'));


    if (this.localhostSearchDataIsPresent == true) {


      var request = {
        "reqBody": {
          "screen": "ebay_orders",
          "filter_params": this.inOrdersPageSearchHappenOrNot.apiRequest
        },
        "offset": this.offsetVal,
        "limit": this.shareThisCount
      }

    } else {

      request = {
        "reqBody": {
          "screen": "ebay_orders",
          "filter_params": [
            {
              "column_name": "order_no",
              "search": this.orderIdValueInOrdersPage || null
            },
            {
              "column_name": "channel_code",
              "search": this.channelIdValueInOrdersPage || null
            },
            {
              "column_name": "channel",
              "search": "EBAY" || null
            },
            {
              "column_name": "email",
              "search": this.emailValueInOrdersPage || null
            },
            {
              "column_name": "name",
              "search": this.customerNameValueInOrdersPage || null
            },

            {
              "column_name": "order_date",
              "search": fromDateToDate
            },

            {
              "column_name": "order_status",
              "search": this.statusValueInOrdersPage || null
            }

          ]
        },
        "offset": this.offsetVal,
        "limit": this.shareThisCount
      }
      if ($('#delivery_date').val()) {
        this.deliveryDate = request.reqBody.filter_params[2].column_name
      }

      if (request.reqBody.filter_params[6].search == "Status...") {
        request.reqBody.filter_params[6].search = null
      }
      if (request.reqBody.filter_params[2].search == "Channel...") {
        request.reqBody.filter_params[2].search = null
      }

    }



    this.service.searchAPI(request).subscribe(res => {
      this.testing = res;

      if (res === null) {
        this.downloadThisFilteredData = [];
        this.noDatafoundOnSearch = true;
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      } else if (this.testing.message) {
        $.notify({
          icon: "add_alert",
          message: this.testing.message
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      }
      else {
        this.noDatafoundOnSearch = false;

        this.pagedItems = res[0].orders;

        this.downloadThisFilteredData = res[0].orders;
        this.totalOrders = res[0].total_order_count;
        if (call === "firstCall") {
          this.setPage(1);
        } else {
          console.log(call);
          this.setPage(call);
        }
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      }
    }, err => {
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    });
  }


  downloadThisAfterFilter() {
    console.log(this.downloadThisFilteredData);
    if (this.noDatafoundOnSearch == true && this.downloadThisFilteredData.length == 0) {
      $.notify({
        icon: "add_alert",
        message: "No Data Found"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    } else {
      if (!this.downloadThisFilteredData || this.downloadThisFilteredData == undefined || this.downloadThisFilteredData == null || this.downloadThisFilteredData == '' || this.downloadThisFilteredData.length == 0) {
        $.notify({
          icon: "add_alert",
          message: "Please Filter the Data"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        var modifiedDownloadArray = []
        for (var i = 0; i < this.downloadThisFilteredData.length; i++) {
          var obj = {
            "Order ID": this.downloadThisFilteredData[i].order_no,
            "Channel ID": this.downloadThisFilteredData[i].channel_code,
            "Channel": this.downloadThisFilteredData[i].channel,
            "Customer Name": this.downloadThisFilteredData[i].first_name + this.downloadThisFilteredData[i].last_name,
            "Email": this.downloadThisFilteredData[i].email,
            "Date & Time": this.downloadThisFilteredData[i].created_at,
            "Amount": this.downloadThisFilteredData[i].total_amount,
            "Payment Type": this.downloadThisFilteredData[i].payment_method,
            "Status": this.downloadThisFilteredData[i].status,
          }
          modifiedDownloadArray.push(obj);
        }


        this.exportService.exportExcel(modifiedDownloadArray, 'Ebay Orders Report');
        modifiedDownloadArray = []

      }

    }
  }


  public reSet() {

    this.downloadThisFilteredData = undefined;
    this.downloadThisFilteredData = [];
    this.noDatafoundOnSearch = false;

    this.resetClicked = true;
    this.orderIdValueInOrdersPage = null;
    this.channelIdValueInOrdersPage = null;
    this.channelValueInOrdersPage = "Channel...";
    this.emailValueInOrdersPage = null;
    this.fromDateValueInOrdersPage = null;
    this.toDateValueInOrdersPage = null;
    this.customerNameValueInOrdersPage = null;
    this.statusValueInOrdersPage = "Status...";

    var isOrderSearch = null;
    var channelSearch = null;
    var channelNameSearch = "Channel...";
    var emailSearch = null;
    var fromDateSearch = null;
    var toDateSearch = null;
    var customerNameSearch = null;
    var statusSearch = "Status...";
    var ordersPageSearchItems = {
      orderID: isOrderSearch,
      channelID: channelSearch,
      channelName: channelNameSearch,
      email: emailSearch,
      fromDate: fromDateSearch,
      todate: toDateSearch,
      customerName: customerNameSearch,
      status: statusSearch
    }
    localStorage.setItem("InEbayPageSelectedSearchItems", JSON.stringify(ordersPageSearchItems))

    var filter_params = [
      {
        "column_name": "order_no",
        "search": null
      },
      {
        "column_name": "channel_code",
        "search": null
      },
      {
        "column_name": "channel",
        "search": null
      },
      {
        "column_name": "email",
        "search": null
      },
      {
        "column_name": "name",
        "search": null
      },
      {
        "column_name": "order_date",
        "search": null
      },
      {
        "column_name": "order_status",
        "search": null
      }

    ];
    if (filter_params[2].search == "Channel...") {
      filter_params[2].search = null;
    }
    if (filter_params[6].search == "Status...") {
      filter_params[6].search = null;
    }


    var setThisObj = {
      apiRequest: filter_params,
      searchHappen: false,
      selectedPageInfo: 1,
      selectedOffsetInfo: 0
    }
    localStorage.setItem("ebayOrdersPageSearchInfo", JSON.stringify(setThisObj))
    this.localhostSearchDataIsPresent = false;


    this.deliveryDate = '';
    $('#order_id_filter').val("");

    $('#chanenl_with_filter').val("");
    $('#customer_mail_id_filter').val("");
    $('#customer_name_id_filter').val("");
    $('#order_date').val("");

    $('#chanenl_name_with_filter').val("");
    $('#payment_type').val("");
    $('#status_filter').val("Status...");
    // this.customeSearch(null, null, "status_filter");
    this.onlyForCSS("status_filter");

    $('#delivery_date').val("");
    $('#store_code').val("");
    $('#mobile_number').val("");

    $('#order_date_id_filter').val("");
    this.fromDateEvent("order_date_id_filter");
    $('#order_todate_id_filter').val("");
    this.fromDateEvent("order_todate_id_filter");





    this.offsetVal = 0;
    this.shareThisCount = 50;
    this.isFliterActive = false;
    this.getAllOrdersList("pageLoad");

    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }

  }
  enableThis1() {
    document.getElementById('order_id_filter').removeAttribute('readonly');
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis2() {
    document.getElementById('order_date').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivary_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis3() {
    document.getElementById('customer_name').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");


  }
  enableThis4() {
    document.getElementById('payment_type').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");


  }


  eraiseAll() {
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#store_code').val("");
    $('#delivery_date').val("");

    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }



  all(data) {


    this.selectedAlldata = data;

    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });

      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {

      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;

    }
  }
  generate() {

    if (this.ShareThisSelectedList > 0) {


      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Order No": this.pagedItems[i].order_no,
            "Created At": this.pagedItems[i].created_at,
            "First Name": this.pagedItems[i].first_name,
            "Last Name": this.pagedItems[i].last_name,
            "Payment Method": this.pagedItems[i].payment_method,
            "Status": this.pagedItems[i].status
          }
          finalDataModified.push(use);
        }
      }
      this.exportService.exportExcel(finalDataModified, 'Order Export');
      $('#selectall').prop("checked", false);
      this.getAllOrdersList();
    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  single(event, data) {

    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }


    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);

      } else {
        $("#selectall").prop('checked', false);

      }
    });

    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }

    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }


  ngOnInit(): void {


    this.detailsData.viewDetails.subscribe(user => {


    });




    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 },

    ]

    this.service.getAllOrderStatus().subscribe(res => {

      this.statustotalList = res;

      this.finalStatus = this.statustotalList.statuses;

      var data = JSON.parse(localStorage.getItem('screensList'));

      this.modifiedStatusArray = data.user_matrix[0].status;


      for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        if (data.user_matrix[0].status[i].status_name === "placed") {
          var re0 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[0] = re0;
        }
        if (data.user_matrix[0].status[i].status_name === "confirmed") {
          var re1 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[1] = re1;
        }
        if (data.user_matrix[0].status[i].status_name === "pickup_confirmed") {
          var reNew = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[2] = reNew;
        }
        if (data.user_matrix[0].status[i].status_name === "in_process") {
          var re2 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[3] = re2;
        }
        if (data.user_matrix[0].status[i].status_name === "ready_to_dispatch") {
          var re3 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[4] = re3;
        }
        if (data.user_matrix[0].status[i].status_name === "out_for_delivery") {
          var re4 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[5] = re4;
        }
        if (data.user_matrix[0].status[i].status_name === "delivered") {
          var re5 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }
          this.currectArray[6] = re5;
        }
        if (data.user_matrix[0].status[i].status_name === "cancelled") {
          var re6 = {
            "is_status_read": data.user_matrix[0].status[i].is_status_read,
            "is_status_write": data.user_matrix[0].status[i].is_status_write,
            "status_id": data.user_matrix[0].status[i].status_id,
            "status_name": data.user_matrix[0].status[i].status_name
          }

          this.currectArray[7] = re6;
        }

      }


      for (var i = 0; i < data.user_matrix[0].status.length; i++) {
        if (data.user_matrix[0].status[i].is_status_write === true) {
          var status = {
            "status": data.user_matrix[0].status[i].status_name
          }
          this.statusArray_main_one_userMatrix.push(data.user_matrix[0].status[i].status_name);
          this.statusArray_main_one_userMatrix_include_key.push(status);
        }

      }
      for (var i = 0; i < (data.user_matrix[0].status.length - 1); i++) {
        if (data.user_matrix[0].status[i].is_status_write === true) {

          this.statusArray.push(data.user_matrix[0].status[i + 1].status_name);
        }

      }

      if (this.statusArray.length === 0) {
        this.hidethisStatus = true;

      } else {
        this.hidethisStatus = false;

      }





      this.loader = false;
    }, err => {

      this.loader = false;
    });


  }



  editedUser(user: string) {
    this.detailsData.editUser(this.newUser);
  }


  previuosOrderCount: any;
  public getOnlyThisOrder(call = null, id) {
    this.loader = true;


    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false,
      'channel_name': 'EBAY'
    }



    this.service.getOrdersByEbay(requestBody).subscribe(res => {




      if (res === null) {
        this.loader = false;
        this.message_display = true;

      } else {
        this.loader = false;
        this.message_display = false;


        this.allOrdersList = res[0].orders;

        this.addingNewiteminOrder = res[0].orders;

        var finalData = _.map(this.addingNewiteminOrder, function (item) {
          if (item.order_no == id) {
            return item;
          }

        })
        finalData = finalData.filter((item) => {
          return item != null;
        });


        this.checkNowAll = finalData;

        this.totalOrders = res[0].total_order_count
        this.allOrdersList = this.checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        }

      }
    }, err => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
    // }
  }

  public getAllOrdersList(call = null) {
    setTimeout(() => {
      this.loader = true;
    }, 900);
    this.selectedPageItemsCount = this.offsetVal;

    var viewBackPageInfoCallData = JSON.parse(localStorage.getItem('viewBackPageInfoCallEbay'));
    if (viewBackPageInfoCallData) {
      this.offsetVal = viewBackPageInfoCallData.count;
      call = viewBackPageInfoCallData.page;
    }


    let requestBody = {
      'user_id': localStorage.getItem('role_id'),
      'offset': this.offsetVal,
      'limit': this.shareThisCount,
      'is_payment_failed': false,
      'channel_name': 'EBAY'
    }



    this.service.getOrdersByEbay(requestBody).subscribe(res => {




      if (res === null) {
        setTimeout(() => {
          this.loader = false;
        }, 1000);
        this.message_display = true;

      } else {
        this.message_display = false;


        this.checkNowAll = res[0].orders;

        this.totalOrders = res[0].total_order_count
        this.allOrdersList = this.checkNowAll.filter(obj => obj.status != "payment_failed");
        this.pagedItems = this.allOrdersList.slice(0);
        if (call === "pageLoad") {
          this.setPage(1);
        } else {
          this.setPage(call);

        }
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      }
    }, err => {
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    });

  }

  filterOrdersByWhenReload(body) {
    var request = {
      "reqBody": body,
      "offset": this.offsetVal,
      "limit": this.shareThisCount
    }

    this.service.searchAPI(request).subscribe(res => {

      if (res === null) {
        this.loader = false;
        $('#status_filter').val("");
        $.notify({
          icon: "add_alert",
          message: "No Data Found"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      } else {
        this.loader = false;
        this.pagedItems = res[0].orders;
        this.totalOrders = res[0].total_order_count;
        this.setPage(1);
      }
    }, err => {

      this.loader = false;
    });
  }
  countPerPage(count) {

    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);
    this.getAllOrdersList();
    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {
    // this.loader = true;
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    // this.loader = false;
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick: string = null) {
    this.selectedPageNumber = page;
    this.ShareThisSelectedList = 0;
    this.selectedPageNumberViewBack = page;
    localStorage.removeItem('viewBackPageInfoCallEbay');
    $('#selectall').prop("checked", false);
    // this.loader = true;
    if (page < 1 || page > this.pager.totalPages) {
      this.loader = false;
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;

    if (numClick !== null && this.isFliterActive == false) {
      this.getAllOrdersList(page);
    } else if (numClick !== null && this.isFliterActive == true) {
      this.customeSearch(null, page, null);
    }
    this.pager = this.getPager(this.totalOrders, page);



  }

  // ----------------------------------------------//
  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr("status");
  }

  onSortClick1(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr1("created_at");
  }

  onSortClickOID(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrOID("order_no");
  }
  onSortClickCN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCusN("first_name", "last_name");


  }
  onSortClickPT(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCN("payment_method");



  }

  sortArr(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sortArr1(colName1: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName1].toLowerCase();
      b = b[colName1].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrOID(colName2: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName2].toLowerCase();
      b = b[colName2].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCN(colName3: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName3].toLowerCase();
      b = b[colName3].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCusN(colName3: any, x: any) {
    this.pagedItems.sort((a, b) => {
      a = (a[colName3] + ' ' + a[x]).toLowerCase();
      b = (b[colName3] + ' ' + b[x]).toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  // --------------------------------------------//





  check_info_ID(e) {

    var request = {
      "screen": "order ID",
      "column_name": "payment type",
      "search": e
    }
    this.loader = true;
    this.service.searchAPI(request).subscribe(res => {

      this.loader = false;
      this.allOrdersList = res[0].orders;
    }, err => {

      this.loader = false;
    });
  }
  check_info_date(d) {


  }
  check_info_name(n) {

    var request = {
      "screen": "order ID",
      "column_name": "customer Name",
      "search": n
    }
    this.loader = true;
    this.service.searchAPI(request).subscribe(res => {

      this.loader = false;
      this.allOrdersList = res[0].orders;
    }, err => {

      this.loader = false;
    });

  }
  check_info_payment_type(p) {


  }
  check_info_status(s) {


  }

  checkTheSeachHappenOrNot() {
    var isOrderSearch = $('#order_id_filter').val();
    var channelSearch = $('#chanenl_with_filter').val();
    var channelNameSearch = $('#chanenl_name_with_filter').val();
    var emailSearch = $('#customer_mail_id_filter').val();
    var fromDateSearch = $('#order_date_id_filter').val();
    var toDateSearch = $('#order_todate_id_filter').val();
    var customerNameSearch = $('#customer_name_id_filter').val();
    var statusSearch = $('#status_filter').val();
    var ordersPageSearchItems = {
      orderID: isOrderSearch,
      channelID: channelSearch,
      channelName: channelNameSearch,
      email: emailSearch,
      fromDate: fromDateSearch,
      todate: toDateSearch,
      customerName: customerNameSearch,
      status: statusSearch
    }
    localStorage.setItem("InEbayPageSelectedSearchItems", JSON.stringify(ordersPageSearchItems))
    if (isOrderSearch) {

      var orderSearchNull = false;
    } else {
      orderSearchNull = true;
    }
    if (channelSearch) {

      var channelSearchNull = false;
    } else {
      channelSearchNull = true;
    }
    if (channelNameSearch) {

      if (channelNameSearch == "Channel...") {
        var channelNameSearchNull = true;
      } else {
        var channelNameSearchNull = false;
      }
    } else {
      channelNameSearchNull = true;
    }
    if (emailSearch) {

      var emailSearchNull = false;
    } else {
      emailSearchNull = true;
    }
    if (fromDateSearch) {

      var fromDateSearchNull = false;
    } else {
      fromDateSearchNull = true;
    }
    if (toDateSearch) {

      var toDateSearchNull = false;
    } else {
      toDateSearchNull = true;
    }
    if (customerNameSearch) {

      var customerNameSearchNull = false;
    } else {
      customerNameSearchNull = true;
    }

    if (statusSearch) {

      if (statusSearch == "Status...") {
        var statusSearchNull = true;
      } else {
        var statusSearchNull = false;
      }
    } else {
      statusSearchNull = true;
    }


    if (orderSearchNull == true && channelSearchNull == true && channelNameSearchNull == true && emailSearchNull == true && fromDateSearchNull == true && toDateSearchNull == true && customerNameSearchNull == true && statusSearchNull == true) {
      this.selectedSearchInfoIsSearchHappen = false;
    } else {
      this.selectedSearchInfoIsSearchHappen = true;
    }


    let fromDateSelectedOne = $('#order_date_id_filter').val() || null
    let toDateSeletedOne = $('#order_todate_id_filter').val() || null;
    if (fromDateSelectedOne == null && toDateSeletedOne == null) {
      var fromDateToDateForSearchHappen = null;
    } else {
      fromDateToDateForSearchHappen = fromDateSelectedOne + "," + toDateSeletedOne
    }

    var filter_params = [
      {
        "column_name": "order_no",
        "search": $('#order_id_filter').val() || null
      },
      {
        "column_name": "channel_code",
        "search": $('#chanenl_with_filter').val() || null
      },
      {
        "column_name": "channel",
        "search": $('#chanenl_name_with_filter').val() || null
      },
      {
        "column_name": "email",
        "search": $('#customer_mail_id_filter').val() || null
      },
      {
        "column_name": "name",
        "search": $('#customer_name_id_filter').val() || null
      },
      {
        "column_name": "order_date",
        "search": fromDateToDateForSearchHappen
      },
      {
        "column_name": "order_status",
        "search": $('#status_filter').val() || null
      }

    ];
    if (filter_params[2].search == "Channel...") {
      filter_params[2].search = null;
    }
    if (filter_params[6].search == "Status...") {
      filter_params[6].search = null;
    }


    var setThisObj = {
      apiRequest: filter_params,
      searchHappen: this.selectedSearchInfoIsSearchHappen,
      selectedPageInfo: this.selectedPageNumber,
      selectedOffsetInfo: this.selectedDataOffset
    }
    localStorage.setItem("ebayOrdersPageSearchInfo", JSON.stringify(setThisObj))
    // ----------------------------------------------------------------------------------------
  }


  public viewValue(Id) {
    this.checkTheSeachHappenOrNot();
    console.log("page", this.selectedPageNumberViewBack);
    console.log("count", this.selectedPageItemsCount);

    var viewBackPageInfo = {
      page: this.selectedPageNumberViewBack,
      count: this.selectedPageItemsCount
    }

    localStorage.setItem("viewBackPageInfoCallEbay", JSON.stringify(viewBackPageInfo))

    this.selectedInfoForView = Id;
    var routingobject = {
      pagename: "ebayorderspage"
    }
    var newobject = Object.assign(Id, routingobject);
    localStorage.setItem("viewOrdersPageDetails", JSON.stringify(newobject));
    this.router.navigate(['/view-orders-page']);
    // this.detailsData.editUser(Id);
  }

  bulkStatus(Index_id, item_s, selected_previous_status) {

    this.sendThis_item_s = item_s;
    this.sendThis_selected_previous_status = selected_previous_status;

    if (item_s.status_name === "cancelled") {
      this.showOnlyForCancel = true;
    } else {
      this.showOnlyForCancel = false;
    }

  }
  reasonClose() {
    this.showThisMsgReasonRequired = false;
    $('#cancelation_reason').val("");
  }
  bulkStatusUpdate() {

    $("#bulk_status_confirmation_open").modal("hide");
    if (this.sendThis_item_s.status_name === "placed") {
      var status = "Placed";
    }
    if (this.sendThis_item_s.status_name === "confirmed") {
      var status = "Confirmed";
    }
    if (this.sendThis_item_s.status_name === "pickup_confirmed") {
      var status = "Pickup Confirmed";
    }
    if (this.sendThis_item_s.status_name === "in_process") {
      var status = "In Process";
    }
    if (this.sendThis_item_s.status_name === "ready_to_dispatch") {
      var status = "Ready To Dispatch";
    }
    if (this.sendThis_item_s.status_name === "out_for_delivery") {
      var status = "Out For Delivery";
    }
    if (this.sendThis_item_s.status_name === "delivered") {
      var status = "Delivered";
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var status = "Cancelled";
    }

    var orderISsArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        if (this.pagedItems[i].status === this.sendThis_selected_previous_status) {
          orderISsArray.push(this.pagedItems[i].order_id);
        }
      }
    }



    if (this.sendThis_item_s.status_name === "cancelled") {
      var cancelationReason = $('#cancelation_reason').val();
      if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
        this.showThisMsgReasonRequired = true;
      } else {
        this.showThisMsgReasonRequired = false;

        var NeworderISsArray = [];
        var NeworderISsArrayNew = [];
        var camparingArray = new Array();
        camparingArray[0] = "cancelled",
          camparingArray[1] = "out_for_delivery",
          camparingArray[2] = "delivered",
          camparingArray[3] = "payment_failed"

        for (var i = 0; i < this.pagedItems.length; i++) {
          if ($('#dynamicID' + i).prop("checked") === true) {
            var n = camparingArray.includes(this.pagedItems[i].status);
            if (n) {
              NeworderISsArrayNew.push(this.pagedItems[i].order_id);
            } else {

              NeworderISsArray.push(this.pagedItems[i].order_id);
            }
          }
        }

        if (NeworderISsArray.length === 0) {

          $.notify({
            icon: "add_alert",
            message: "You Can't change Into" + " " + status + " " + "Status"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.loader = false;
          this.selectedAlldata = [];

          NeworderISsArray = [];
          this.arraychecking = [];

        } else {
          var cancelationReason = $('#cancelation_reason').val();

          var reqCancel = {
            "status_id": this.sendThis_item_s.status_id,
            "order_id": NeworderISsArray,
            "reason": cancelationReason
          }

          this.loader = true;
          this.bulk_update_waiting = true;
          this.service.updateBulk(reqCancel).then(async res => {

            this.updateMesg = res;
            await this.updateMesg;
            $('#cancelation_reason').val("");

            $('#selectall').prop("checked", false);
            this.bulk_update_waiting = false;
            this.loader = false;
            $.notify({
              icon: "add_alert",
              message: this.updateMesg.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];

            NeworderISsArray = [];
            this.arraychecking = [];

            this.showThisMsgReasonRequired = false;
            this.getAllOrdersList();
          }).catch(err => {

            this.selectedAlldata = [];
            NeworderISsArray = [];

            this.loader = false;

            $('#selectall').prop("checked", false);
            $.notify({
              icon: "add_alert",
              message: err.error.error_desc
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];
            this.arraychecking = [];
          });
        }
      }
    } else {
      if (orderISsArray.length === 0) {
        $.notify({
          icon: "add_alert",
          message: "You Can't change Into" + " " + status + " " + "Status"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
        this.selectedAlldata = [];

        NeworderISsArray = [];
        this.arraychecking = [];


      } else {

        var req = {
          "status_id": this.sendThis_item_s.status_id,
          "order_id": orderISsArray,
          "reason": null
        }

        this.loader = true;
        this.bulk_update_waiting = true;
        this.service.updateBulk(req).then(res => {

          this.updateMesg = res;

          $('#selectall').prop("checked", false);
          this.bulk_update_waiting = false;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.updateMesg.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          NeworderISsArray = [];
          this.selectedAlldata = [];
          this.arraychecking = [];

          this.getAllOrdersList();
        }).catch(err => {

          NeworderISsArray = [];
          this.selectedAlldata = [];

          this.arraychecking = [];


          $('#selectall').prop("checked", false);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: err.error.error_desc
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }
  }

  closethisNow() {

    $("#bulk_status_confirmation_close").trigger("click");
  }



  onPrintInvoice(item) {

    this.service.getInvoiceData(item.order_id).subscribe(res => {

      this.testingNow = res;
      this.ref.detectChanges();
      var dataInfo = document.getElementById('elem');
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(dataInfo.innerHTML); WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();

    }, err => {

      window.close();
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
  }

  handleAsideClick(event: Event) {
    event.stopPropagation();
  }
  isSearchSuggestion: boolean;
  searchedProducts: any;
  searchLoader: boolean;
  search(searchText, event) {
    this.loader = true;
    if (event.keyCode == 13) {
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    if (searchText.length < 3) {
      this.searchedProducts = '';
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    this.searchLoader = true;


    let requestBody = {
      "param": {
        "type": "search",//search or category
        "productName": searchText,// only if search
        // "store_id": this.sharethisInfo.store_id,
        "store_id": localStorage.getItem('plant_code_add_product'),
        "categoryId": "",
        "sort_by": '',
        "pageNumber": "",
        "pageCount": "",
        "filters": {
        }
      }
    }

    this.sharedService.productSearch(requestBody).subscribe(data => {

      this.searchLoader = false;
      this.searchedProducts = data[0].products;

      this.isSearchSuggestion = true;
      this.loader = false;
    }, err => {

      this.loader = false;
    });
  }

  skuCode: any;
  searchSelect(product) {
    this.isSearchSuggestion = false;
    this.searchkey = product.name + '' + product.sku
    this.skuCode = product.sku
  }


  productItem: any;

  bulkProduck(form) {

    this.isSubmit = true
    if (form.valid) {
      this.loader = true
      var request = {
        "sku": form.value.skuCode || null,
        "store_code": form.value.storeSelect || null,
        "status": form.value.status || null,
        "reason": form.value.reason || null,
        "quantity": +(form.value.quantity) || null,
      }
      this.service.productRemovel(request).subscribe((data) => {
        this.loader = false

        this.productItem = data;
        if (data === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.productItem.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      }, err => {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No orders found with this SKU"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      })

    }
  }

  walletPopup() {
    let requestBody = {
      "order_id": this.selectedOrderItem.order_id,
      "wallet_amount": this.selectedOrderItem.wallet_refund_amount
    }
    this.loader = true;
    this.sharedService.walletRevasal(requestBody).subscribe((data: any) => {
      this.walletRevalsal = data
      this.loader = false;

      if (data.message) {
        document.getElementById("toggleWalletUp").click();
        this.selectedOrderItem = ''
      } else {
        $.notify({
          icon: "add_alert",
          message: "Wallet amount is not present"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      }
    }, erro => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Wallet amount is not present"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    })
  }

  selectedOrderItem
  refundWallet(item) {
    this.selectedOrderItem = item;

    document.getElementById("toggleWalletUp").click()
  }
  fileURL: any;
  bulkProductPrint() {
    let orderIds = []
    this.selectedAlldata.forEach(item => {
      orderIds.push(item.order_id)
    })
    this.loader = true;
    if (orderIds.length) {
      let requestBody = {
        "order_id": orderIds
      }
      this.sharedService.bulkProdukPrint(requestBody).subscribe((data: any) => {

        this.loader = false;
        var blob = new Blob([data], { type: 'application/pdf' }); //this make the magic
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

      })
    } else {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Select"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }


  }
  playAudio() {
    let audio = new Audio();

    audio.src = "assets/sounds/buzzer.wav"
    audio.load();
    audio.play();
  }

  getOrdersbyselectedOMSID(selectedOMSData) {

    this.selectedOMSIDorderID = [];


    for (var i = 0; i < this.pagedItems.length; i++) {
      if (selectedOMSData.order_no == this.pagedItems[i].order_no) {
        var obj = {
          selectedChannelID: this.pagedItems[i].channelId
        }
        this.selectedOMSIDorderID.push(obj)
      }
    }

    this.selectedOMSIDorderIDfinal = this.selectedOMSIDorderID[0].selectedChannelID

  }





}
