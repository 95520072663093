import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs/';
import { map, catchError, switchMap } from 'rxjs/operators';
import { SharedServiceService } from './shared-service.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inject: Injector) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf("login") > - 1 || req.url.indexOf("refresh") > - 1) {
      return next.handle(req);
    } else {
      let sharedService = this.inject.get(SharedServiceService);
      let jwtToken = req;
      jwtToken = this.AddTokenHeader(req, sharedService.getToken())
      return next.handle(jwtToken).pipe(
        catchError(errorData => {
          if (errorData.status === 401) {
            return this.handelRefresToken(req, next);
          }
          if (errorData.status === 500) {
            console.log("giving 500 error");
            $.notify({
              icon: "add_alert",
              message: "Please try after some time"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
          }
        })
      )
    }
  }
  handelRefresToken(req: HttpRequest<any>, next: HttpHandler) {
    let sharedService = this.inject.get(SharedServiceService);
    return sharedService.generateRefreshToken().pipe(
      switchMap((data: any) => {
        sharedService.saveTokens(data);
        return next.handle(this.AddTokenHeader(req, data.access))
      })
    );
  }
  AddTokenHeader(req: HttpRequest<any>, token: any) {
    return req.clone(
      { headers: req.headers.set('Authorization', 'Bearer ' + token) }
    )
  }


}
