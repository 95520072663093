import {
  Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef,
  HostListener, ElementRef, ChangeDetectionStrategy
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { from } from 'rxjs';
import { data } from 'jquery';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-initiatereturn',
  templateUrl: './initiatereturn.component.html',
  styleUrls: ['./initiatereturn.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InitiatereturnComponent implements OnInit {

  buttonHover = ""
  filterOrders = "";
  isButtonEnable: boolean;
  pos: string;
  post: string;
  dynamicorderDetailsByCheckBox: any[];
  dict = {};
  selectedInfoForView: any;
  selectedInfoForEdit: any;
  selectedOption = 'default';
  selectedOptionn = 'default';
  finalData: { order_id: string; order_date: string; delivery_date: string; customer_name: string; payment_status: string; order_type: string; status: string; }[];
  selectedAlldata = [];
  selectedAlldataArray = [];
  allOrdersList: any;
  loader: boolean;
  message_display: boolean = false;
  showThisScreenWithPermissions: any;
  permissionForOnlyThisScreen = [];
  allPermissionForOnlyThisScreen: any;
  statustotalList: any;
  custome_download: FormGroup;
  productBulkRemoval: FormGroup;
  custome_search: FormGroup;
  submitted: boolean;
  from_date: any;
  to_date: any;
  customeData: any;
  pageOfItems: any;
  first_id: any;
  last_id: any;
  totalElements: any;
  forFilterUse: any;
  fullfillData: any;
  config: any;
  id: any;
  pager: any = {};
  pagedItems = [];
  finalStatus: any;
  status_with_time: any;
  statusTimeStampArray = [];
  finalArray: any;
  modifiedStatusArray: any;
  statusArray = [];
  updateMesg: any;
  NewStatusArray = [];
  hidethisStatus: boolean;
  NewStatusArray_final = [];
  statusArray_main_one_userMatrix = [];
  currectArray = [];
  statusArray_main_one_userMatrix_include_key = [];
  thisIsMatched: boolean;
  arraychecking = [];
  sendThis_item_s: any;
  sendThis_selected_previous_status: any;
  showThisSelectMSG: boolean;
  showOnlyForCancel: boolean;
  showThisMsgReasonRequired: boolean;
  pageCount = [];
  howmanySelectedArray = [];
  ShareThisSelectedList: number = 0;
  onlyForDashboard: any;
  sortDir = 1;
  testingNow: any;
  shareThisCount: any = 20;
  offsetVal: number = 0;
  totalOrders: any;
  public isFliterActive: boolean = false;
  public bulk_update_waiting: boolean = false;
  searchkey: any
  isSubmit: boolean;
  checkNowAll: any;
  walletRevalsal: any;
  public moment: any = moment;
  newUser: any;
  user: string;
  initialLoad: boolean = true
  deliveryDate: any;
  testing: Object;
  delivery = ('#delivery_date'.replace('-', '/'));
  buzzerStatus: any;
  storeCodes: any;
  storedataresponse: any;
  storeCodeDataNew: any;
  allStoresIds: any;
  addingNewiteminOrder: any;
  selectedOMSIDorderID: any;
  selectedOMSIDorderIDfinal: any;
  fromGBCIDInfo: any;
  fromGBCID: any;
  returnOrderIdd: any;
  moveToAllOrdersList: boolean = false;
  orderEmail: any;
  totalreasons: any;
  itemsData: any;
  Customerbillingaddresses: any;
  Customershippingaddresses: any;
  returnorderstatus: boolean = false;
  shipmentoptionsData: any;
  shipmentOptionsInfo: any;
  selectedShipperName: any;
  shipperServiceData: any;
  shipperServiceInfo: any;
  selectedShipperServiceData: any;
  selectedReturenReason: any;
  selectedOrderDetails: any;
  returnAndreplacementData: boolean;
  returnAndrefundData: boolean;
  proceedToInitiatedReturn: boolean;
  showThis45DaysError: any;
  showthisitemafterclick: boolean = true;
  returnTypeInfo: any;
  selectedReturnTypeInfo: any;
  hideThisShipperShippingInfoBlock: boolean;
  returnAndBlockdata: boolean;
  logInUser: any;
  returnWithoutLabeldata: boolean;
  resultForAPI: any;
  shippingAddressLinefirstnameInViewOrdersPage: any;
  shippingAddressLinelastnameInViewOrdersPage: any;
  shippingAddressLineOneInViewOrdersPage: any;
  shippingAddressLineTwoInViewOrdersPage: any;
  shippingAddressCountryInViewOrdersPage: any;
  shippingAddressStateInViewOrdersPage: any;
  shippingAddressCityInViewOrdersPage: any;
  shippingAddressStreetInViewOrdersPage: any;
  shippingAddressPincodeInViewOrdersPage: any;
  showThisCompleteAddresMess: string;
  shippingAddressmobileInViewOrdersPage: any;
  afterAddressUpdated: any;
  paramsForInitiateReturnInfo: any;
  permissionInfo: any;
  customReasonType: boolean = false;
  constructor(private ref: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder,
    private exportService: ExportService, private service: SharedServiceService, public sharedService: SharedServiceService,
    private detailsData: ExportService, private route: ActivatedRoute) {
      this.paramsForInitiateReturnInfo = JSON.parse(localStorage.getItem('paramsForInitiateReturn'));
      // this.route.queryParams.subscribe(res => {
      // console.log(res);
      this.returnOrderIdd = this.paramsForInitiateReturnInfo.OrderNo;
      this.orderEmail = this.paramsForInitiateReturnInfo.Email;
      this.permissionInfo = this.paramsForInitiateReturnInfo.permission;
  
      // this.orderzipCode = res.Pincode;
      // this.orderzipCode = res.Pincode.replace(/\s/g, '').substring(0, 5);
      this.checkorderstatus();
  

    // })


    this.fromGBCID = this.route.params.subscribe(params => {
      console.log(params);
      this.fromGBCIDInfo = params;
      if (this.fromGBCIDInfo.order_no) {
        console.log(true);
        // this.getOnlyThisOrder("pageLoad", this.fromGBCIDInfo.order_no);
        this.moveToAllOrdersList = true;
      } else {
        // this.getAllOrdersList("pageLoad");
        this.route.queryParams.subscribe(queryParams => {

          if (Object.keys(this.route.snapshot.queryParams).length == 0 && !this.initialLoad) {
            // this.getAllOrdersList("pageLoad");
          }
        });
        if (Object.keys(this.route.snapshot.queryParams).length != 0 && this.initialLoad) {
          this.initialLoad = false;
          // this.filterOrdersByWhenReload(this.route.snapshot.queryParams)
        } else {
          this.initialLoad = false;
          // this.getAllOrdersList("pageLoad");
        }

      }
    });


    this.onlyForDashboard = localStorage.getItem('dashboard');
    console.log(this.onlyForDashboard);
    this.showThisScreenWithPermissions = JSON.parse(localStorage.getItem('screensList'));


    for (var i = 0; i < this.showThisScreenWithPermissions.screens.length; i++) {
      if (this.showThisScreenWithPermissions.screens[i].screen_name === "Orders") {
        var forOrders = {
          "screen_id": this.showThisScreenWithPermissions.screens[i].screen_id,
          "screen_name": this.showThisScreenWithPermissions.screens[i].screen_name,
          "is_read": this.showThisScreenWithPermissions.screens[i].is_read,
          "is_write": this.showThisScreenWithPermissions.screens[i].is_write
        }
        this.permissionForOnlyThisScreen.push(forOrders);
      }
    }

    this.allPermissionForOnlyThisScreen = this.permissionForOnlyThisScreen[0];
    console.log(this.allPermissionForOnlyThisScreen);

    this.custome_download = this.formBuilder.group({
      from_date: [''],
      to_date: [''],
      status_id: [0]
    });

    this.productBulkRemoval = this.formBuilder.group({
      skuCode: ['', Validators.required],
      storeSelect: ['default', Validators.required],
      status: ['', Validators.required],
      reason: ['', Validators.required],
      quantity: ['']


    });


    this.custome_search = this.formBuilder.group({
      order_id: [''],
      order_date: [''],
      customer_name: [''],
      payment_type: [''],
      order_status: [''],
      mobile_number: [''],
      delivery_date: [''],

    });
  }




  get a() { return this.custome_download.controls; }
  get b() { return this.custome_search.controls; }

  public selectedStatusInfor(v) {

    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#mobile_number').val("");
    $('#delivery_date').val("");




  }
  public customeExport() {
    this.loader = true;
    this.submitted = true;
    if (this.custome_download.invalid) {
      this.loader = false;
      return;
    }

    this.from_date = new Date(this.a.from_date.value);
    this.to_date = new Date(this.a.to_date.value);

    if ((this.to_date - this.from_date) >= 0) {
      console.log(this.custome_download.value);
      this.service.downloadCustomeorders(this.custome_download.value).subscribe(res => {
        console.log(res);
        if (res === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.customeData = res;

          let date = "All order";
          if (this.a.from_date.value && this.a.to_date.value) {
            date = `Order Export - ${moment(this.a.from_date.value).format("DD/MM/YYYY")} -${moment(this.a.to_date.value).format("DD/MM/YYYY")}`;
          }
          this.exportService.exportExcel(this.customeData[0].orderfilter, date);
          this.loader = false;
          $('#from_date').val("");
          $('#to_date').val("");
          $('#selectedStatus').val("");
          this.submitted = false;
          this.custome_download.reset();
        }
      }, err => {

        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: err.error.error_desc
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      });
    } else {
      $('#to_date').val("");

      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "To Date Should be Gater then From Date! Please currect the Date and try again"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }

  }

  customeDownloads() {
    $('#from_date').val("");
    $('#to_date').val("");
    $('#selectedStatus').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    this.custome_download.reset();
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }
  bulkProduct() {
    $('#sku').val("");
    $('#store_code').val("");
    $('#status').val("");
    $('#reason').val("");
    $('#quantity').val("");
    this.submitted = false;
    let closeFilter = document.getElementById("collapse2");
    if (closeFilter.classList.contains('show')) {
      closeFilter.classList.remove('show');
    }
    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }

  }

  public reSet() {
    this.deliveryDate = '';
    $('#order_id_filter').val("");

    $('#chanenl_with_filter').val("");
    $('#customer_mail_id_filter').val("");
    $('#customer_name_id_filter').val("");
    $('#order_date').val("");

    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");
    $('#mobile_number').val("");




    this.offsetVal = 0;
    this.shareThisCount = 20;
    this.isFliterActive = false;

    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }

  }
  enableThis1() {
    document.getElementById('order_id_filter').removeAttribute('readonly');
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");

  }
  enableThis2() {
    document.getElementById('order_date').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivary_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");

  }
  enableThis3() {
    document.getElementById('customer_name').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#payment_type").attr("readonly", "true");

  }
  enableThis4() {
    document.getElementById('payment_type').removeAttribute('readonly');
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#status_filter').val("");
    $('#delivery_date').val("");
    $('#store_code').val("");



    $("#order_id_filter").attr("readonly", "true");
    $("#order_date").attr("readonly", "true");
    $("#customer_name").attr("readonly", "true");


  }


  eraiseAll() {
    $('#order_id_filter').val("");
    $('#order_date').val("");
    $('#customer_name').val("");
    $('#payment_type').val("");
    $('#status_filter').val("");
    $('#store_code').val("");
    $('#delivery_date').val("");

    let closeDownload = document.getElementById("collapse1");
    if (closeDownload.classList.contains('show')) {
      closeDownload.classList.remove('show');
    }
    let bulkProductR = document.getElementById("collapse3");
    if (bulkProductR.classList.contains('show')) {
      bulkProductR.classList.remove('show');
    }
  }



  all(data) {
    this.selectedAlldata = data;
    if ($('#selectall').prop("checked") == true) {
      $('body').on('click', '#selectall', function () {
        $('.singlechkbox').prop('checked', this.checked);
      });
      this.ShareThisSelectedList = this.pagedItems.length;
    }
    else if ($('#selectall').prop("checked") == false) {
      this.selectedAlldata = [];
      this.ShareThisSelectedList = 0;

    }
  }
  generate() {
    if (this.ShareThisSelectedList > 0) {

      var finalDataModified = [];
      for (var i = 0; i < this.pagedItems.length; i++) {
        if ($('#dynamicID' + i).prop("checked") === true) {
          var use = {
            "Order No": this.pagedItems[i].order_no,
            "Created At": this.pagedItems[i].created_at,
            "First Name": this.pagedItems[i].first_name,
            "Last Name": this.pagedItems[i].last_name,
            "Payment Method": this.pagedItems[i].payment_method,
            "Status": this.pagedItems[i].status
          }
          finalDataModified.push(use);
        }
      }
      this.exportService.exportExcel(finalDataModified, 'Order Export');
      $('#selectall').prop("checked", false);

    } else {
      $.notify({
        icon: "add_alert",
        message: "Please select the List"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }
  }
  single(event, data) {
    // console.log(data);
    if (this.dict.hasOwnProperty(event)) {
      delete this.dict[event];
    } else {
      this.dict[event] = data;
    }
    this.selectedAlldata = [];
    for (var key in this.dict) {
      this.selectedAlldata.push(this.dict[key]);
    }

    $('body').on('click', '.singlechkbox', function () {
      if ($('.singlechkbox').length == $('.singlechkbox:checked').length) {
        $('#selectall').prop('checked', true);

      } else {
        $("#selectall").prop('checked', false);

      }
    });

    this.howmanySelectedArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        this.howmanySelectedArray.push(i);
      }
    }
    this.ShareThisSelectedList = this.howmanySelectedArray.length;

  }
  getInputDateFormat(date) {
    return date.toISOString().split('T')[0];
  }

  validDate() {
    var today = new Date();
    var maxDate = new Date();

    document.getElementsByName("from_date_dd")[0].setAttribute('max', this.getInputDateFormat(today));
    document.getElementsByName('to_date_dd')[0].setAttribute('max', this.getInputDateFormat(maxDate));
  }


  ngOnInit(): void {
    this.returnreason();
    this.shipmentOptions();
    this.logInUser = localStorage.getItem('role');




    if (this.logInUser == "CRM Manager" || this.logInUser == "Warehouse" || this.logInUser == "CRM User" || this.logInUser == "Ecom User" || this.logInUser == "Finance") {
      this.returnTypeInfo = [
        { type: "Return & Refund" },
        { type: "Return & Replacement" }
      ]
    }

    if (this.logInUser == "IT User" || this.logInUser == "Admin") {
      this.returnTypeInfo = [
        { type: "Return & Refund" },
        { type: "Return & Replacement" },
        { type: "Return & Block" }
      ]
    }

    if (this.logInUser == "Brand Partner") {
      this.returnTypeInfo = [
        { type: "Return & Block" }
      ]
    }



    this.detailsData.viewDetails.subscribe(user => {

    });



    this.pageCount = [
      { "count": 10 },
      { "count": 20 },
      { "count": 50 },
      { "count": 100 },
      { "count": 500 },

    ]
  }



  editedUser(user: string) {
    this.detailsData.editUser(this.newUser);
  }


  previuosOrderCount: any;

  countPerPage(count) {
    this.shareThisCount = count;
    this.offsetVal = 0;
    this.isFliterActive = false;
    $('#selectall').prop("checked", false);

    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.shareThisCount) {

    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }


    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let pages = _.range(startPage, endPage + 1);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number, numClick: string = null) {
    this.ShareThisSelectedList = 0;
    $('#selectall').prop("checked", false);

    if (page < 1 || page > this.pager.totalPages) {
      this.loader = false;
      return;
    }
    this.offsetVal = (page - 1) * this.shareThisCount;

    if (numClick !== null && this.isFliterActive == false) {
    } else if (numClick !== null && this.isFliterActive == true) {
    }
    this.pager = this.getPager(this.totalOrders, page);



  }

  // ----------------------------------------------//
  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr("status");
  }

  onSortClick1(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArr1("created_at");
  }

  onSortClickOID(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrOID("order_no");
  }
  onSortClickCN(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCusN("first_name", "last_name");



  }
  onSortClickPT(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains("fa-chevron-up")) {
      classList.remove("fa-chevron-up");
      classList.add("fa-chevron-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-chevron-up");
      classList.remove("fa-chevron-down");
      this.sortDir = 1;
    }
    this.sortArrCN("payment_method");


  }

  sortArr(colName: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sortArr1(colName1: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName1].toLowerCase();
      b = b[colName1].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrOID(colName2: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName2].toLowerCase();
      b = b[colName2].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCN(colName3: any) {
    this.pagedItems.sort((a, b) => {
      a = a[colName3].toLowerCase();
      b = b[colName3].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  sortArrCusN(colName3: any, x: any) {
    this.pagedItems.sort((a, b) => {
      a = (a[colName3] + ' ' + a[x]).toLowerCase();
      b = (b[colName3] + ' ' + b[x]).toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }
  // --------------------------------------------//








  public viewValue(Id) {
    this.selectedInfoForView = Id;
    this.router.navigate(['/view-orders-page', Id]);

  }

  bulkStatus(Index_id, item_s, selected_previous_status) {
    this.sendThis_item_s = item_s;
    this.sendThis_selected_previous_status = selected_previous_status;

    if (item_s.status_name === "cancelled") {
      this.showOnlyForCancel = true;
    } else {
      this.showOnlyForCancel = false;
    }

  }
  reasonClose() {
    this.showThisMsgReasonRequired = false;
    $('#cancelation_reason').val("");
  }
  bulkStatusUpdate() {
    $("#bulk_status_confirmation_open").modal("hide");
    if (this.sendThis_item_s.status_name === "placed") {
      var status = "Placed";
    }
    if (this.sendThis_item_s.status_name === "confirmed") {
      var status = "Confirmed";
    }
    if (this.sendThis_item_s.status_name === "pickup_confirmed") {
      var status = "Pickup Confirmed";
    }
    if (this.sendThis_item_s.status_name === "in_process") {
      var status = "In Process";
    }
    if (this.sendThis_item_s.status_name === "ready_to_dispatch") {
      var status = "Ready To Dispatch";
    }
    if (this.sendThis_item_s.status_name === "out_for_delivery") {
      var status = "Out For Delivery";
    }
    if (this.sendThis_item_s.status_name === "delivered") {
      var status = "Delivered";
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var status = "Cancelled";
    }
    var orderISsArray = [];
    for (var i = 0; i < this.pagedItems.length; i++) {
      if ($('#dynamicID' + i).prop("checked") === true) {
        if (this.pagedItems[i].status === this.sendThis_selected_previous_status) {
          orderISsArray.push(this.pagedItems[i].order_id);
        }
      }
    }
    if (this.sendThis_item_s.status_name === "cancelled") {
      var cancelationReason = $('#cancelation_reason').val();
      if (cancelationReason === "" || cancelationReason === undefined || cancelationReason === null) {
        this.showThisMsgReasonRequired = true;
      } else {
        this.showThisMsgReasonRequired = false;
        var NeworderISsArray = [];
        var NeworderISsArrayNew = [];
        var camparingArray = new Array();
        camparingArray[0] = "cancelled",
          camparingArray[1] = "out_for_delivery",
          camparingArray[2] = "delivered",
          camparingArray[3] = "payment_failed"
        for (var i = 0; i < this.pagedItems.length; i++) {
          if ($('#dynamicID' + i).prop("checked") === true) {
            var n = camparingArray.includes(this.pagedItems[i].status);
            if (n) {
              NeworderISsArrayNew.push(this.pagedItems[i].order_id);
            } else {

              NeworderISsArray.push(this.pagedItems[i].order_id);
            }
          }
        }
        if (NeworderISsArray.length === 0) {

          $.notify({
            icon: "add_alert",
            message: "You Can't change Into" + " " + status + " " + "Status"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.loader = false;
          this.selectedAlldata = [];

          NeworderISsArray = [];
          this.arraychecking = [];
        } else {
          var cancelationReason = $('#cancelation_reason').val();
          var reqCancel = {
            "status_id": this.sendThis_item_s.status_id,
            "order_id": NeworderISsArray,
            "reason": cancelationReason
          }
          this.loader = true;
          this.bulk_update_waiting = true;
          this.service.updateBulk(reqCancel).then(async res => {
            this.updateMesg = res;
            await this.updateMesg;
            $('#cancelation_reason').val("");

            $('#selectall').prop("checked", false);
            this.bulk_update_waiting = false;
            this.loader = false;
            $.notify({
              icon: "add_alert",
              message: this.updateMesg.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];

            NeworderISsArray = [];
            this.arraychecking = [];
            this.showThisMsgReasonRequired = false;

          }).catch(err => {
            this.selectedAlldata = [];
            NeworderISsArray = [];
            this.loader = false;

            $('#selectall').prop("checked", false);
            $.notify({
              icon: "add_alert",
              message: err.error.error_desc
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.selectedAlldata = [];
            this.arraychecking = [];
          });
        }
      }
    } else {
      if (orderISsArray.length === 0) {
        $.notify({
          icon: "add_alert",
          message: "You Can't change Into" + " " + status + " " + "Status"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
        this.selectedAlldata = [];

        NeworderISsArray = [];
        this.arraychecking = [];

      } else {

        var req = {
          "status_id": this.sendThis_item_s.status_id,
          "order_id": orderISsArray,
          "reason": null
        }
        this.loader = true;
        this.bulk_update_waiting = true;
        this.service.updateBulk(req).then(res => {
          this.updateMesg = res;

          $('#selectall').prop("checked", false);
          this.bulk_update_waiting = false;
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.updateMesg.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          NeworderISsArray = [];
          this.selectedAlldata = [];
          this.arraychecking = [];
        }).catch(err => {
          NeworderISsArray = [];
          this.selectedAlldata = [];

          this.arraychecking = [];

          $('#selectall').prop("checked", false);
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: err.error.error_desc
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        });

      }
    }
  }

  closethisNow() {

    $("#bulk_status_confirmation_close").trigger("click");
  }


  onPrintInvoice(item) {
    this.service.getInvoiceData(item.order_id).subscribe(res => {
      this.testingNow = res;
      this.ref.detectChanges();
      var dataInfo = document.getElementById('elem');
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(dataInfo.innerHTML); WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();

    }, err => {
      window.close();
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: err.error.error_desc
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    });
  }

  handleAsideClick(event: Event) {
    event.stopPropagation();
  }
  isSearchSuggestion: boolean;
  searchedProducts: any;
  searchLoader: boolean;
  search(searchText, event) {
    this.loader = true;
    if (event.keyCode == 13) {
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    if (searchText.length < 3) {
      this.searchedProducts = '';
      this.isSearchSuggestion = false;
      this.loader = false;
      return
    }
    this.searchLoader = true;

    let requestBody = {
      "param": {
        "type": "search",
        "productName": searchText,
        "store_id": localStorage.getItem('plant_code_add_product'),
        "categoryId": "",
        "sort_by": '',
        "pageNumber": "",
        "pageCount": "",
        "filters": {
        }
      }
    }
    this.sharedService.productSearch(requestBody).subscribe(data => {
      this.searchLoader = false;
      this.searchedProducts = data[0].products;
      this.isSearchSuggestion = true;
      this.loader = false;
    }, err => {
      this.loader = false;
    });
  }

  skuCode: any;
  searchSelect(product) {
    this.isSearchSuggestion = false;
    this.searchkey = product.name + '' + product.sku
    this.skuCode = product.sku
  }


  productItem: any;

  bulkProduck(form) {
    this.isSubmit = true
    if (form.valid) {
      this.loader = true
      var request = {
        "sku": form.value.skuCode || null,
        "store_code": form.value.storeSelect || null,
        "status": form.value.status || null,
        "reason": form.value.reason || null,
        "quantity": +(form.value.quantity) || null,
      }
      this.service.productRemovel(request).subscribe((data) => {
        this.loader = false
        this.productItem = data;
        if (data === null) {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: "No Data Available!"
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        } else {
          this.loader = false;
          $.notify({
            icon: "add_alert",
            message: this.productItem.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
        }
      }, err => {
        this.loader = false;
        $.notify({
          icon: "add_alert",
          message: "No orders found with this SKU"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      })
    }
  }

  walletPopup() {
    let requestBody = {
      "order_id": this.selectedOrderItem.order_id,
      "wallet_amount": this.selectedOrderItem.wallet_refund_amount
    }
    this.loader = true;
    this.sharedService.walletRevasal(requestBody).subscribe((data: any) => {
      this.walletRevalsal = data
      this.loader = false;
      if (data.message) {
        document.getElementById("toggleWalletUp").click();
        this.selectedOrderItem = ''
      } else {
        $.notify({
          icon: "add_alert",
          message: "Wallet amount is not present"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
      }
    }, erro => {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Wallet amount is not present"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    })
  }

  selectedOrderItem
  refundWallet(item) {
    this.selectedOrderItem = item;
    document.getElementById("toggleWalletUp").click()
  }
  fileURL: any;
  bulkProductPrint() {
    let orderIds = []
    this.selectedAlldata.forEach(item => {
      orderIds.push(item.order_id)
    })
    this.loader = true;
    if (orderIds.length) {
      let requestBody = {
        "order_id": orderIds
      }
      this.sharedService.bulkProdukPrint(requestBody).subscribe((data: any) => {
        this.loader = false;
        var blob = new Blob([data], { type: 'application/pdf' });
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

      })
    } else {
      this.loader = false;
      $.notify({
        icon: "add_alert",
        message: "Please Select"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
    }


  }
  playAudio() {
    let audio = new Audio();

    audio.src = "assets/sounds/buzzer.wav"
    audio.load();
    audio.play();
  }

  getOrdersbyselectedOMSID(selectedOMSData) {
    this.selectedOMSIDorderID = [];

    for (var i = 0; i < this.pagedItems.length; i++) {
      if (selectedOMSData.order_no == this.pagedItems[i].order_no) {
        var obj = {
          selectedChannelID: this.pagedItems[i].channelId
        }
        this.selectedOMSIDorderID.push(obj)
      }
    }
    this.selectedOMSIDorderIDfinal = this.selectedOMSIDorderID[0].selectedChannelID

  }




  checkorderstatus() {
    setTimeout(() => {
      this.loader = true;
    }, 900);
    this.orderEmail
    this.returnOrderIdd
    var requestbody = {
      "order_no": this.returnOrderIdd,
      "email": encodeURIComponent(this.orderEmail),
      "permission": this.permissionInfo,
      "user_email": encodeURIComponent(localStorage.getItem('User_Email'))

    }
    this.service.searchcheckorderstatus(requestbody).subscribe(res => {
      this.itemsData = res[0];

      this.returnorderstatus = true;
      for (let item of this.itemsData.items) {
        
        item.isNameAqProtect = item.name.startsWith("AQ Protect");
       
        // this.hideTheFinalSubmitButton = false;
      }
      if (this.logInUser == "CRM Manager" || this.logInUser == "Warehouse" || this.logInUser == "CRM User" || this.logInUser == "Ecom User" || this.logInUser == "Finance") {

        if (this.itemsData.channel == "TARGET" || this.itemsData.channel == "WALMART") {
          this.returnTypeInfo = [
            { type: "Return & Replacement" }
          ]
        } else {
          this.returnTypeInfo = [
            { type: "Return & Refund" },
            { type: "Return & Replacement" }
            // { type: "Return Without Label" }

          ]
        }


      }

      if (this.logInUser == "IT User" || this.logInUser == "Admin") {
        if (this.itemsData.channel == "TARGET" || this.itemsData.channel == "WALMART") {
          this.returnTypeInfo = [
            { type: "Return & Replacement" }
          ]
        } else {
          this.returnTypeInfo = [
            { type: "Return & Refund" },
            { type: "Return & Replacement" },
            { type: "Return & Block" }
            // { type: "Return Without Label" }
          ]
        }
      }


      if (this.logInUser == "Brand Partner") {
        this.returnTypeInfo = [
          { type: "Return & Block" }
        ]
      }




      this.Customerbillingaddresses = this.itemsData.addresses[0].billing_address[0]
      this.Customershippingaddresses = this.itemsData.addresses[0].shipping_address[0]

      if (this.Customershippingaddresses.mobile == 'None' || this.Customershippingaddresses.mobile == null || this.Customershippingaddresses.mobile.includes("*******") || this.Customershippingaddresses.mobile == '' || this.Customershippingaddresses.mobile == 'none') {
        this.Customershippingaddresses.mobile = null;
      }
      if (this.Customerbillingaddresses.mobile == 'None' || this.Customerbillingaddresses.mobile == null || this.Customerbillingaddresses.mobile.includes("*******") || this.Customerbillingaddresses.mobile == '' || this.Customerbillingaddresses.mobile == 'none') {
        this.Customerbillingaddresses.mobile = null;
      }


      if (this.Customershippingaddresses.first_name == null || this.Customershippingaddresses.first_name.includes("*******")) {
        this.Customershippingaddresses.first_name = null;
      }
      if (this.Customershippingaddresses.last_name == null || this.Customershippingaddresses.last_name.includes("*******")) {
        this.Customershippingaddresses.last_name = null;

      }
      if (this.Customershippingaddresses.line_1 == null || this.Customershippingaddresses.line_1.includes("*******")) {
        this.Customershippingaddresses.line_1 = null;

      }
      if (this.Customershippingaddresses.line_2 == null || this.Customershippingaddresses.line_2.includes("*******")) {
        this.Customershippingaddresses.line_2 = null;

      }
      if (this.Customershippingaddresses.city == null || this.Customershippingaddresses.city.includes("*******")) {
        this.Customershippingaddresses.city = null;

      }
      if (this.Customershippingaddresses.state == null || this.Customershippingaddresses.state.includes("*******")) {
        this.Customershippingaddresses.state = null;

      }
      if (this.Customershippingaddresses.country == null || this.Customershippingaddresses.country.includes("*******")) {
        this.Customershippingaddresses.country = null;

      }
      if (this.Customershippingaddresses.pincode == null || this.Customershippingaddresses.pincode == '' || this.Customershippingaddresses.pincode.includes("*******")) {
        this.Customershippingaddresses.pincode = null;
      }

      this.shipmentOptions();
      this.returnreason();

      this.checkCondition();
      this.shipmentservicetypes();
      // this.loader = false;
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    }, err => {


      this.showThis45DaysError = err.error.error_desc;
      this.returnorderstatus = false;
      // this.loader = false;
      setTimeout(() => {
        this.loader = false;
      }, 1000);

    })



  }

  checkCondition() {


    if (this.logInUser == "CRM Manager" || this.logInUser == "IT User") {
      this.hideThisShipperShippingInfoBlock = true;

    } else {



      var checkConditionArray = [];
      this.itemsData.items.filter(item => {
        if (item.message == "Initiate_return") {
          checkConditionArray.push(true);
        } else {
          checkConditionArray.push(false);
        }
      });

      if (checkConditionArray.includes(true)) {
        this.hideThisShipperShippingInfoBlock = true;
      } else {
        this.hideThisShipperShippingInfoBlock = false;
      }

    }
  }
  returnreason() {
    this.service.getallreason().subscribe(res => {

      this.totalreasons = res;
    }, err => {


    })
  }
  returnreasonsselect(selectedData, val) {
    this.selectedReturenReason = selectedData;
    console.log(this.selectedReturenReason);

    if (this.itemsData.channel == "STRIDE RITE" || this.itemsData.channel == "ANDRE ASSOUS" || this.itemsData.channel == "AQUATALIA") {
      if (this.selectedReturenReason == "Shipment not received") {
        this.selectedOptionn = "Return Without Label";
        this.selectedReturnTypeInfo = "Return Without Label";
        this.customReasonType = true;
      } else {
        this.selectedOptionn = 'default';
        this.selectedReturnTypeInfo = null;
        this.customReasonType = false;
      }
    }


    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "return_reason") {
          if (val.target.value != null && val.target.value == "Return Reason") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
  }

  shipmentOptions() {
    this.service.ordershipmentoptions().subscribe(res => {
      this.shipmentoptionsData = res;
      this.shipmentOptionsInfo = this.shipmentoptionsData.shipment_option

      this.shipmentOptionsInfo = [];
      if (this.itemsData.channel == "ANDRE ASSOUS") {
        this.shipmentOptionsInfo.push("FedEx");
      }
      if (this.itemsData.channel == "AQUATALIA") {
        this.shipmentOptionsInfo.push("UPS");
      }
      else {
        this.shipmentOptionsInfo.push("USPS");
      }


    }, err => {


    })
  }
  shipmentoptionselect(selectedData) {

    this.selectedShipperName = selectedData;
    this.shipmentservicetypes();
  }

  shipmentservicetypes() {
    if (this.itemsData.channel == "ANDRE ASSOUS") {
      this.selectedShipperName = "FedEx"
    } 
    if (this.itemsData.channel == "AQUATALIA") {
      this.selectedShipperName = "UPS"
    } 
    else {
      this.selectedShipperName = "USPS"
    }
    var reqbody = {
      "shipping_option": this.selectedShipperName
    }

    this.service.gethipmentservicetypes(reqbody).subscribe(res => {
      this.shipperServiceData = res;
      this.shipperServiceInfo = this.shipperServiceData.shipping_service_type;
      this.shipperServiceInfo = [];
      if (this.selectedShipperName == "FedEx") {
        this.shipperServiceInfo.push("FEDEX GROUND");
      } 
      if (this.selectedShipperName == "UPS") {
        this.shipperServiceInfo.push("UPS PRIORITY MAIL");
      }
      else {
        this.shipperServiceInfo.push("USPS PRIORITY MAIL");
      }
      this.selectedShipperServiceData = this.shipperServiceInfo[0];


    }, err => {

    });
  }

  shipperServiceSelectedInfo(selectedData) {

    this.selectedShipperServiceData = selectedData;
  }
  // ---------------------------------------------
  returnTypeSelectedInfo(selectedType, val) {
    this.selectedReturnTypeInfo = selectedType;

    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "return_type_filter") {
          if (val.target.value != null && val.target.value == "Return Type ...") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }

  }
  // ---------------------------------------------

  selectAllItems(selectedOrder) {
    this.customReasonType = false;
    this.selectedOption = 'default';
    this.selectedOptionn = 'default';
    this.selectedOrderDetails = selectedOrder;
    var checkboxesSelectedOrnot = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        checkboxesSelectedOrnot.push(false);
      }
      else {
        checkboxesSelectedOrnot.push(true);
      }
    }
    if (checkboxesSelectedOrnot.includes(false)) {
      this.showthisitemafterclick = false;
    } else {
      this.showthisitemafterclick = true;
    }

  }




  InitiateReturn() {
    // setTimeout(() => {
      this.loader = true;
    // }, 900);
    var finalSelectedCheckBoxes = [];
    var finalSelectedCheckBoxesOrder_part_no = [];
    for (var i = 0; i < this.itemsData.items.length; i++) {
      if ($('#dynamicIDNew' + i).prop('checked') == true) {
        finalSelectedCheckBoxes.push(this.itemsData.items[i].item_id);
        finalSelectedCheckBoxesOrder_part_no.push(this.itemsData.items[i].order_part_no)
      }
      else {

      }
    }

    if (this.selectedReturnTypeInfo == "Return & Replacement") {
      this.returnAndreplacementData = true;
      this.returnAndrefundData = false;
      this.returnAndBlockdata = false;
      this.returnWithoutLabeldata = false;
    }
    if (this.selectedReturnTypeInfo == "Return & Refund") {
      this.returnAndreplacementData = false;
      this.returnAndrefundData = true;
      this.returnAndBlockdata = false;
      this.returnWithoutLabeldata = false;
    }
    if (this.selectedReturnTypeInfo == "Return & Block") {
      this.returnAndreplacementData = false;
      this.returnAndrefundData = false;
      this.returnAndBlockdata = true;
      this.returnWithoutLabeldata = false;
    }
    if (this.selectedReturnTypeInfo == "Return Without Label") {
      this.returnAndreplacementData = false;
      this.returnAndrefundData = false;
      this.returnAndBlockdata = false;
      this.returnWithoutLabeldata = true;
    }

    if (this.selectedReturnTypeInfo == null || this.selectedReturnTypeInfo == undefined || !this.selectedReturnTypeInfo) {
      this.returnAndreplacementData = undefined;
      this.returnAndrefundData = undefined;
      this.returnAndBlockdata = undefined;
      this.returnWithoutLabeldata = undefined;
    }



    var requestBody = {
      "order_id": this.itemsData.order_id,
      "order_parts": finalSelectedCheckBoxesOrder_part_no,
      "order_no": this.itemsData.order_no,
      "item_id": finalSelectedCheckBoxes,
      "return_reason": this.selectedReturenReason,
      "return_refund": this.returnAndrefundData,
      "return_replacement": this.returnAndreplacementData,
      "return_blocked": this.returnAndBlockdata,
      "return_without_label": this.returnWithoutLabeldata,
      "channel_name": this.itemsData.channel,
      "shipment_option": this.selectedShipperName,
      "shipping_service_type": this.selectedShipperServiceData,
      "user_email": this.decodeURIComponent(localStorage.getItem('User_Email')),
    }
    if (requestBody.return_reason == undefined || requestBody.return_refund == undefined || requestBody.return_replacement == undefined || requestBody.shipment_option == undefined || requestBody.shipping_service_type == undefined || requestBody.item_id.length == 0) {
      this.proceedToInitiatedReturn = true;

      $.notify({
        icon: "add_alert",
        message: "Please select all"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
      this.loader = false;
    } else {
      this.proceedToInitiatedReturn = false;

      this.service.initiatereturnorders(requestBody).subscribe(res => {
        this.resultForAPI = res;
        if (this.resultForAPI.error_string) {
          this.showThisCompleteAddresMess = this.resultForAPI.error_string;
          if (this.Customershippingaddresses.first_name == "" || this.Customershippingaddresses.first_name == null || this.Customershippingaddresses.first_name == "*******") {
            this.shippingAddressLinefirstnameInViewOrdersPage = null;
          } else {
            this.shippingAddressLinefirstnameInViewOrdersPage = this.Customershippingaddresses.first_name;
          }

          if (this.Customershippingaddresses.last_name == "" || this.Customershippingaddresses.last_name == null || this.Customershippingaddresses.last_name == "*******") {
            this.shippingAddressLinelastnameInViewOrdersPage = null;
          } else {
            this.shippingAddressLinelastnameInViewOrdersPage = this.Customershippingaddresses.last_name;
          }


          if (this.Customershippingaddresses.line_1 == "" || this.Customershippingaddresses.line_1 == null || this.Customershippingaddresses.line_1 == "*******") {
            this.shippingAddressLineOneInViewOrdersPage = null;
          } else {
            this.shippingAddressLineOneInViewOrdersPage = this.Customershippingaddresses.line_1;
          }

          if (this.Customershippingaddresses.line_2 == "" || this.Customershippingaddresses.line_2 == null || this.Customershippingaddresses.line_2 == "*******") {
            this.shippingAddressLineTwoInViewOrdersPage = null;
          } else {
            this.shippingAddressLineTwoInViewOrdersPage = this.Customershippingaddresses.line_2;
          }
          if (this.Customershippingaddresses.city == "" || this.Customershippingaddresses.city == null || this.Customershippingaddresses.city == "*******") {
            this.shippingAddressCityInViewOrdersPage = null;
          } else {
            this.shippingAddressCityInViewOrdersPage = this.Customershippingaddresses.city;
          }

          if (this.Customershippingaddresses.state == "" || this.Customershippingaddresses.state == null || this.Customershippingaddresses.state == "*******") {
            this.shippingAddressStateInViewOrdersPage = null;
          } else {
            this.shippingAddressStateInViewOrdersPage = this.Customershippingaddresses.state;
          }



          if (this.Customershippingaddresses.pincode == "" || this.Customershippingaddresses.pincode == null || this.Customershippingaddresses.pincode == "*******") {
            this.shippingAddressPincodeInViewOrdersPage = null;
          } else {
            this.shippingAddressPincodeInViewOrdersPage = this.Customershippingaddresses.pincode;
          }

          if (this.Customershippingaddresses.country == "" || this.Customershippingaddresses.country == null || this.Customershippingaddresses.country == "*******") {
            this.shippingAddressCountryInViewOrdersPage = null;
          } else {
            this.shippingAddressCountryInViewOrdersPage = this.Customershippingaddresses.country;
          }

          if (this.Customershippingaddresses.mobile == "" || this.Customershippingaddresses.mobile == "None" || this.Customershippingaddresses.mobile == "none" || this.Customershippingaddresses.mobile == null || this.Customershippingaddresses.mobile == "*******") {
            this.shippingAddressmobileInViewOrdersPage = null;
          } else {
            this.shippingAddressmobileInViewOrdersPage = this.Customershippingaddresses.mobile;
          }


          $('#editShippingAddressNew').attr("data-toggle", "modal");
          $('#editShippingAddressPopUpInReturnsPage').modal("show");
          this.loader = false;

        } else {
          this.router.navigate(['/orders']);
          $('#editShippingAddressNew').attr("data-toggle", "");
          this.showThisCompleteAddresMess = null;
          if (requestBody.return_refund == true) {
            $.notify({
              icon: "add_alert",
              message: "Order Refund initiated successfully"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }
          if (requestBody.return_replacement == true) {
            $.notify({
              icon: "add_alert",
              message: "Order Return and Replacement happen successfully"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }
          if (requestBody.return_blocked == true) {
            $.notify({
              icon: "add_alert",
              message: "Order Return and Block happen successfully"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }
          if (requestBody.return_without_label == true) {
            $.notify({
              icon: "add_alert",
              message: "Order Return without label happen successfully"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.loader = false;
          }

        }
      }, err => {
        $.notify({
          icon: "add_alert",
          message: err.error
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        this.loader = false;
      });

    }



  }
  decodeURIComponent(encodedString: string): string {
    return decodeURIComponent(encodedString.replace(/%2F/g, '/').replace(/%3A/g, ':').replace(/%40/g, '@'));
  }

  closeThisEditShippingAddress() {
    this.showThisCompleteAddresMess = null;
    $('#editShippingAddressNew').attr("data-toggle", "");

  }
  saveTheEditedShippingAddressInViewOrdersPage() {
    var requestBody = {
      "order_id": this.itemsData.order_id,
      "first_name": this.shippingAddressLinefirstnameInViewOrdersPage,
      "last_name": this.shippingAddressLinelastnameInViewOrdersPage,
      "line_1": this.shippingAddressLineOneInViewOrdersPage,
      "line_2": this.shippingAddressLineTwoInViewOrdersPage,
      "pincode": this.shippingAddressPincodeInViewOrdersPage,
      "channel_name": this.itemsData.channel,
      "city": this.shippingAddressCityInViewOrdersPage,
      "state": this.shippingAddressStateInViewOrdersPage,
      "country": this.shippingAddressCountryInViewOrdersPage,
      "mobile": this.shippingAddressmobileInViewOrdersPage,
      "user_email": localStorage.getItem('User_Email')
    }



    if (requestBody.first_name == null || requestBody.first_name == "" || requestBody.last_name == null || requestBody.last_name == "" || requestBody.line_1 == "" || requestBody.line_1 == null || requestBody.pincode == null || requestBody.pincode == "" || requestBody.city == null || requestBody.city == "" || requestBody.state == null || requestBody.state == "" || requestBody.country == null || requestBody.country == "") {
      this.showThisCompleteAddresMess = "Kindly provide all the mandatory fields for shipping address";
      $('#saveShippingAddressEdit').attr("data-dismiss", "");
    } else {
      if (this.itemsData.channel != "STRIDE RITE") {
        if (requestBody.mobile == null || requestBody.mobile == "" || requestBody.mobile == 'None') {
          this.showThisCompleteAddresMess = "Kindly provide all the mandatory fields for shipping address";
          $('#saveShippingAddressEdit').attr("data-dismiss", "");
        } else {
          this.showThisCompleteAddresMess = null;
          this.service.saveTheEditedAddressInReturnsPageAPI(requestBody).subscribe(res => {
            this.afterAddressUpdated = res;
            $('#editShippingAddressNew').attr("data-toggle", "");
            $('#saveShippingAddressEdit').attr("data-dismiss", "modal");
            $('#editShippingAddressPopUpInReturnsPage').modal("hide");
            $.notify({
              icon: "add_alert",
              message: this.afterAddressUpdated.message
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            this.checkorderstatus();
            this.selectAllItems(this.selectedOrderDetails);
            this.showthisitemafterclick = true;
          }, error => {
            $('#editShippingAddressNew').attr("data-toggle", "");
          });
        }
      } else {
        this.showThisCompleteAddresMess = null;
        this.service.saveTheEditedAddressInReturnsPageAPI(requestBody).subscribe(res => {
          this.afterAddressUpdated = res;
          $('#editShippingAddressNew').attr("data-toggle", "");
          $('#saveShippingAddressEdit').attr("data-dismiss", "modal");
          $('#editShippingAddressPopUpInReturnsPage').modal("hide");
          $.notify({
            icon: "add_alert",
            message: this.afterAddressUpdated.message
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          this.checkorderstatus();
          this.selectAllItems(this.selectedOrderDetails);
          this.showthisitemafterclick = true;
        }, error => {
          $('#editShippingAddressNew').attr("data-toggle", "");
        });
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onKeyPress(event: KeyboardEvent) {
    var inp = String.fromCharCode(event.keyCode);


    if (/[a-zA-Z\s]/.test(inp)) {

      return true;
    } else {

      event.preventDefault();
      return false;
    }
  }


  lineonKeyPress(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }



}
