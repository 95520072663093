import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedServiceService } from 'app/_services/shared-service.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
declare var $: any;
@Component({
  selector: 'app-create-new-gift-card',
  templateUrl: './create-new-gift-card.component.html',
  styleUrls: ['./create-new-gift-card.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateNewGiftCardComponent implements OnInit {

  coupon_types: any;
  addNewCouponForm: FormGroup;
  loader: boolean;
  submitted: boolean;
  value_type_list: any;
  coupon_status: any;
  requestBody: any;
  shareThisInfo: any;
  createCoupanSres: any;
  AllstoresInfo: any;
  finalStoresListArray: any;
  currentCouponType: any;
  storeCode: any;
  giftcardpostresponse: any;
  selectedReason: any;
  mailId: any;
  amount: any;
  orderid: any;
  channelname: any;

  gctype: any;
  selectedValue: any;
  selectedGcType: any;
  timeoutId: any;
  reasonsforGcReturn: string[];
  couponTypes: any = [
    {
      value: "general",
      viewValue: "General"
    },
    {
      value: "user_specific",
      viewValue: "User Specific"
    },
  ];
  emailList: any;
  contact_list: any;
  currentCT: string = "general"
  notes: any;

  giftcardbalanceamount: any;
  loginuser: any;
  isSubmitting: boolean = false;
  constructor(private router: Router, private formBuilder: FormBuilder, private service: SharedServiceService) {
    this.addNewCouponForm = this.formBuilder.group({

      email_input: [''],

    });

  }
  get a() { return this.addNewCouponForm.controls; }

  ngOnInit(): void {


    this.coupon_types = [
      {
        "coupon_type": "General",
        "val": "general"
      },
      {
        "coupon_type": "User Specific",
        "val": "user_specific"
      }
    ];

    this.coupon_status = [
      {
        "coupon_status": "Active",
        "val": true
      },
      {
        "coupon_status": "Inactive",
        "val": false
      }
    ];


    this.value_type_list = [
      {
        "type": "Amount",
        "value_type": "amount"
      },
      {
        "type": "Percentage",
        "value_type": "percentage"
      }
    ];
  }
  selectedType(val) {
    // console.log(val);
    this.shareThisInfo = val;
    if (val === "percentage") {
      $('#showThepersentage').css("display", "block");
      this.addNewCouponForm.get('coupon_amount').setValue("");
    } else {
      $('#showThepersentage').css("display", "none");
      this.addNewCouponForm.get('coupon_amount').setValue("");
    }
  }



  mailSelected(val) {
    // console.log(val);

    this.mailId = val

    // console.log("mailId", this.mailId);
  }
  orderSelected(val) {
    // console.log(val);


  }
  ChannelIdSelected(val) {
    // console.log(val);

    this.channelname = val

    // console.log("Channelname", this.channelname);
  }
  amountSelected(val) {
    // console.log(val);

    this.amount = val

    // console.log("Amount", this.amount);



  }
  notesSelected(val) {
    // console.log(val);
    this.notes = val

    // console.log("Notes", this.notes);
  }
  reason(selected, val) {
    this.selectedReason = selected;
    // console.log(this.selectedReason);
    // console.log(val);
    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "secondreasonselect") {
          if (val.target.value != null && val.target.value == "Select options for Gc...") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }
  }
  ReasonIdSelected(selectedValue: any, val) {
    // console.log(val);
    if (val) {
      if (typeof (val) != 'string') {
        if (val.srcElement.id == "reasonselect") {
          if (val.target.value != null && val.target.value == "Gc Type...") {
            $('#' + val.srcElement.id).addClass("preSelect");
            $('#' + val.srcElement.id).removeClass("postSelect");
          } else {
            $('#' + val.srcElement.id).addClass("postSelect");
            $('#' + val.srcElement.id).removeClass("preSelect");
          }
        }
      } else {
        $('#' + val).addClass("preSelect");
        $('#' + val).removeClass("postSelect");
      }
    }

    this.selectedValue = selectedValue;

    this.loginuser = localStorage.getItem('role');
    // console.log(this.loginuser);
    this.selectedValue = selectedValue;
    if ((this.loginuser != "CRM Manager" || this.loginuser != "Admin" || this.loginuser != "IT User") && selectedValue == "GC Return") {
      this.reasonsforGcReturn = ["Gift card purchase return", "Scrap return"];
      this.selectedGcType = "GC Return";
      // console.log(this.reasonsforGcReturn);

    }
    if (selectedValue == "GC Return" && (this.loginuser == "CRM Manager" || this.loginuser == "Admin" || this.loginuser == "IT User")) {
      this.reasonsforGcReturn = ["52+ day old return", "Gift card purchase return", "Scrap return", "Item less than $20"];
      this.selectedGcType = "GC Return";
    }
    if (selectedValue == "Appeasement") {
      this.reasonsforGcReturn = ["Billing Issue", "Credit shipping fee", "Damaged/defective item", "Inconvenience", "Late shipment", "Missing item", "Negative customer review", "Order not received/delivery error", "Other", "Poor customer service", "Price adjustment", "Promotion was not correctly applied", "Sales tax", "Wrong item shipped"];
      this.selectedGcType = "Appeasement";
    }

    // console.log("selectedValue:", selectedValue);
    // console.log("selectedGcType:", this.selectedGcType);
  }

  giftcardbalance(val) {

    // console.log(val);
    if (!val || val == null || val == undefined || val == '') {
      $('#giftcardinput').val("");
      this.amount = '';
      // console.log("removed");
    }
    this.orderid = val
    // console.log("orderid", this.orderid);

    this.service.giftcardremainingvalues(this.orderid).subscribe(res => {
      // console.log(res);
      this.giftcardbalanceamount = res;

      // console.log(this.giftcardbalanceamount);
      if (this.giftcardbalanceamount.remaining_value != "None") {
        this.amount = this.giftcardbalanceamount.remaining_value;
        $('#giftcardinput').val(this.giftcardbalanceamount.remaining_value);
      }

    }, err => {
      // console.log(err);

    })
  }

  createNewCoupons() {
 
    if (this.isSubmitting) {
      console.log('Button is disabled because an API call is already in progress.');
      return; // Exit if an API call is already in progress
    }
    this.isSubmitting = true; // Set flag to true when API call starts
    this.loader = true; // Show loader
    // setTimeout(() => {
    //   this.loader = true;
    // }, 500);
    setTimeout(() => {
      this.isSubmitting = false;  // Re-enable the button after 3 seconds
    }, 3000);
    this.requestBody = {


      "user_email": localStorage.getItem('User_Email'),
      "customer_email": this.mailId,
      "amount": this.amount,
      "reason": this.selectedReason,
      "gctype": this.selectedValue,
      "order_no": this.orderid,

      "notes": this.notes
    }

    // console.log(this.requestBody);
    // this.requestBody.notes == null || this.requestBody.notes == undefined || 
    if (this.requestBody.order_no == null || this.requestBody.order_no == undefined || this.requestBody.gctype == null || this.requestBody.gctype == undefined || this.requestBody.reason == null || this.requestBody.reason == undefined || this.requestBody.amount == null || this.requestBody.amount == undefined || this.requestBody.customer_email == null || this.requestBody.customer_email == undefined) {
      $.notify({
        icon: "add_alert",
        message: "Please fill all the fields"
      }, {
        type: 'info',
        timer: 1000,
        placement: {
          from: 'top',
          align: 'center'
        }
      });
      setTimeout(() => {
        this.loader = false;
      }, 500);
    } else {
      setTimeout(() => {
        this.loader = false;
      }, 500);



      var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (this.mailId.match(validRegex)) {
        // console.log(this.mailId);
        // console.log("Valid email address!");
        // console.log(Number(this.requestBody.amount));
        // console.log(Number(this.giftcardbalanceamount.remaining_value));

        var editedAmount = Number(this.requestBody.amount);
        var responseAmountFromAPI = Number(this.giftcardbalanceamount.remaining_value);


        if ((editedAmount) > (responseAmountFromAPI)) {
          $.notify({
            icon: "add_alert",
            message: "Please enter less than $" + responseAmountFromAPI
          }, {
            type: 'info',
            timer: 1000,
            placement: {
              from: 'top',
              align: 'center'
            }
          });
          setTimeout(() => {
            this.loader = false;
          }, 500);
        } else {
          // console.log("Okay");
          if (this.giftcardbalanceamount.remaining_value == 0.00 || this.giftcardbalanceamount.remaining_value == 0) {
            $.notify({
              icon: "add_alert",
              message: "Insufficient Balance"
            }, {
              type: 'info',
              timer: 1000,
              placement: {
                from: 'top',
                align: 'center'
              }
            });
            setTimeout(() => {
              this.loader = false;
            }, 500);
          }

          else {
            this.service.createNewCoupan(this.requestBody).subscribe(res => {
              // console.log(res);
              this.giftcardpostresponse = res
              this.loader = false;
              $('#mailinput').val("");
              $('#giftcardinput').val("");
              $('#reasonselect').val("Gc Type...");
              this.ReasonIdSelected(null, "reasonselect");
              $('#channelselect').val("");
              $('#orderinput').val("");
              $('#secondreasonselect').val("Select options for Gc...");
              this.reason(null, "secondreasonselect");
              $('#notestext').val("");

              $.notify({
                icon: "add_alert",
                message: this.giftcardpostresponse.message
              }, {
                type: 'info',
                timer: 1000,
                placement: {
                  from: 'top',
                  align: 'center'
                }
              });
              this.isSubmitting = false;
              // this.loader = false;

            }, err => {
              // console.log(err);
              this.loader = false;
              this.isSubmitting = false;
            });
          }
        }





      } else {
        // console.log(this.mailId);
        $.notify({
          icon: "add_alert",
          message: "Please Enter the Valid email address"
        }, {
          type: 'info',
          timer: 1000,
          placement: {
            from: 'top',
            align: 'center'
          }
        });
        // console.log("Invalid email address!");
      }
    }







  }
  resetForm() {
    $('#mailinput').val("");
    $('#giftcardinput').val("");
    $('#reasonselect').val("Gc Type...");
    this.ReasonIdSelected(null, "reasonselect");
    $('#channelselect').val("");
    $('#orderinput').val("");
    $('#secondreasonselect').val("Select options for Gc...");
    this.reason(null, "secondreasonselect");
    $('#notestext').val("");
  }
  
  cancel() {
    this.router.navigate(['/gift-card-management']);
  }
  numberOnly() {
    $('.filterme').keypress(function (eve) {
      if ((eve.which != 46 || $(this).val().indexOf('.') != -1) && (eve.which < 48 || eve.which > 57) || (eve.which == 46 && $(this).caret().start == 0)) {
        eve.preventDefault();
      }


      $('.filterme').keyup(function (eve) {
        if ($(this).val().indexOf('.') == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  }
  startdate() {
    var fromDate = $('#start_date').val();
    var toDate = $('#end_date').val();
    // console.log(fromDate);

    if ((new Date(JSON.stringify(fromDate)).getTime()) <= (new Date(JSON.stringify(toDate)).getTime())) {
    } else {
      $('#end_date').val(null);
      this.addNewCouponForm.get('coupon_end_date').setValue("");
    }
  }
  enddate() {
    var fromDate = $('#start_date').val();
    var toDate = $('#end_date').val();
    if ((new Date(JSON.stringify(toDate)).getTime()) >= (new Date(JSON.stringify(fromDate)).getTime())) {
    } else {
      $('#start_date').val(null);
      this.addNewCouponForm.get('coupon_start_date').setValue("");
    }
  }
  checkValidation() {
    var date = new Date();
    var day = JSON.stringify(date.getDate())
    if (date.getDate() < 10) {
      day = "0" + day
    } else {
      day = day;
    }
    var month = JSON.stringify(date.getMonth() + 1)
    if ((date.getMonth() + 1) < 10) {
      month = "0" + month
    } else {
      month = month;
    }
    var year = date.getFullYear();
    var minDate = year + '-' + month + '-' + day;
    // console.log(minDate);


    $('#start_date').attr('min', minDate);

    $('#end_date').attr('min', minDate);
  }

}
